import React, { useContext } from "react";

// Material-UI
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles, alpha } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";

// Internal
import CustomTooltip from "@Components/CustomTooltip";
import { MinimizeContext } from "../../context/MinimizeContext";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import Language from "sccLanguage";
import Geofence from "sccGeofence";
import GeofenceOverlay from "sccGeofenceOverlay";
import Permission from "sccPermission";
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
  dataDisplayButtonGroup: {
    borderRadius: "90 !important",
    width: "100% !important",
    "& .dataDisplayButton": {
      padding: "5px 10px",
      borderRadius: "0",
      width: "30px",
      minWidth: "30px",
      height: "30px",
      marginRight: "3px !important",
      border: `1px solid ${theme.palette.colors.gray.dark} !important`,
      backgroundColor: alpha(theme.palette.colors.black.main, 0.2),
    },
    "& :hover": {
      bgcolor: theme.palette.colors.gray.dark,
    },
    "& span span": {
      display: "flex",
      alignItems: "center",
    },
    "& .svg": {
      fontSize: "16px",
    },
    "& .locked": {
      boackground: theme.palette.colors.yellow.main,
    },
  },
}));

export default function DataDisplayButtonGroup(props) {
  const { state } = useContext(MinimizeContext);
  const [mwState] = useContext(ModuleWrapperContext);

  const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;

  const geofenceId = GeofenceOverlay.getSelectedId();
  const geofenceData = Geofence.get(geofenceId);

  if (!geofenceData) return null;

  const isFederationData = Boolean(geofenceData?.federationServerId);

  const geofenceObject = {
    tab: "Geofence",
    shortTitle: "GEO",
    title: "Geo-Fence",
    moduleName: "Geofence",
    module: Geofence,
    imageClass: "geofence",
    extLink: false,
    isList: true,
    wrapperDisplayMode: MW_LIST_MODE,
    showFilter: false,
    showPagination: true,
    showSearch: true,
    searchField: ["title"],
    showFooter: true,
    verifyAddPermission: Permission.verify("geofence", "add"),
    verifyEditPermission: Permission.verify("geofence", "edit"),
    verifyDeletePermission: Permission.verify("geofence", "delete"),
    verifyShownPermission: Permission.verify("geofence", "view"),
    mapLayer: GeofenceOverlay,
  };

  const handleDialogOpenClose = (e) => {
    mwState.setDialogOkAction(() => okActionClose(e));
    mwState.setDialogCancelAction(() => cancelAction());
    mwState.setDialogOpen(true);
  };
  function okActionClose(e) {
    handleEdit(e);
    mwState.setDialogOpen(false);
  }
  function cancelAction() {
    mwState.setDialogOpen(false);
  }

  const handleEdit = () => {
    // fixes the visual geofence border issue on preselected geofences.
    GeofenceOverlay.resetFeatureStyleToPreEdit();
    const deviceId = GeofenceOverlay.getSelectedId();
    const geofenceData = Geofence.get(deviceId);
    const feature = geofenceData.id
      ? GeofenceOverlay.getFeature(geofenceData.id)
      : null;
    GeofenceOverlay.setDrawFeature(feature);
    // open right edit popup
    GeofenceOverlay.deselectFeature();
    mwState.openModuleWrapperPopper(geofenceObject);
    mwState.handleItemEdit(geofenceData);
  };
  const handleZoom = () => {
    GeofenceOverlay.centerAndZoomTo(GeofenceOverlay.getSelectedId());
  };

  const classes = useStyles();

  const showButtonGroup = !state.minimize ? (
    <ButtonGroup
      className={classes.dataDisplayButtonGroup}
      aria-label="outlined primary button group"
    >
      {Permission.verify("geofence", "edit") &&
      !window.name.includes("Extended") &&
      !window.name.includes("History") &&
      !isFederationData ? (
        <Button
          className="dataDisplayButton"
          onClick={(e) => {
            !mwState.moduleFormHasChanges
              ? handleEdit(e)
              : handleDialogOpenClose();
          }}
        >
          <CustomTooltip
            title={Language.translate("Edit")}
            arrow
            placement="top"
          >
            {" "}
            <EditIcon />
          </CustomTooltip>
        </Button>
      ) : null}

      <Button className="dataDisplayButton" onClick={handleZoom}>
        <CustomTooltip
          title={Language.translate("Zoom In")}
          arrow
          placement="top"
        >
          <GpsFixedIcon />
        </CustomTooltip>
      </Button>
    </ButtonGroup>
  ) : (
    ""
  );
  return <div>{showButtonGroup}</div>;
}
