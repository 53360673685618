import { useContext, useEffect, useRef } from "react";

import { FederationDataContext } from "./context/FederationDataContext";
import { ModuleWrapperContext } from "../../reusable-components/ModuleWrapper/ModuleWrapperContext";
import UserSetting from "sccUserSetting";
import Utils from "sccUtils";

export default function FederationData(props) {
  const [fedState] = useContext(FederationDataContext);
  const [mwState, setMwState] = useContext(ModuleWrapperContext);

  const searchField = mwState.searchField;
  const searchTerm = mwState.searchTerm;
  const filteredList = mwState.filteredList;
  const filterSelection = mwState.selectedFilter;

  const checkFederationConnection = fedState.checkFederationConnection;
  const fetchFederationData = fedState.fetchFederationData;
  const removeFederationData = fedState.removeFederationData;

  const fetchIntervalRef = useRef(null);
  const checkIntervalRef = useRef(null);
  useEffect(() => {
    // Function to start checking connection every 10 sec
    const startConnectionCheck = () => {
      if (!checkIntervalRef.current) {
        checkFederationConnection();
        checkIntervalRef.current = setInterval(
          checkFederationConnection,
          10 * 1000
        );
      }
    };

    // Function to start fetching data every 10 sec and check connection every 30 sec
    const startFetchingData = () => {
      if (!fetchIntervalRef.current) {
        fetchFederationData();
        fetchIntervalRef.current = setInterval(fetchFederationData, 60 * 1000);
      }
      if (!checkIntervalRef.current) {
        checkIntervalRef.current = setInterval(
          checkFederationConnection,
          30 * 1000
        );
      }
    };

    // Function to stop all intervals
    const stopAllIntervals = () => {
      if (fetchIntervalRef.current) {
        clearInterval(fetchIntervalRef.current);
        fetchIntervalRef.current = null;
      }
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
        checkIntervalRef.current = null;
      }
    };

    if (
      fedState.federationConnection?.federationServerId &&
      UserSetting.get()?.display_federation_data
    ) {
      stopAllIntervals();
      startFetchingData();
    } else {
      stopAllIntervals();
      removeFederationData();
      startConnectionCheck();
    }

    return () => stopAllIntervals();
  }, [
    fedState.federationConnection?.federationServerId,
    UserSetting.get()?.display_federation_data,
  ]);

  useEffect(() => {
    const updateModuleData = (updatedData) => {
      const filteredData = filteredList(
        Object.values(updatedData),
        filterSelection
      );
      const filteredModuleData = Utils.arrayToObject(filteredData, "id");
      const numberOfItems = filteredModuleData
        ? Object.keys(filteredModuleData).length
        : 0;

      setMwState((p) => ({
        ...p,
        moduleFederationData: [],
        moduleData: updatedData,
        filteredModuleData: filteredModuleData,
        pagination: {
          ...p.pagination,
          numberOfItems: numberOfItems,
          endIndex:
            numberOfItems < p.pagination.itemsPerPage
              ? numberOfItems
              : p.pagination.endIndex,
        },
      }));
    };

    if (!Utils.getFedKeyNameForModule(mwState.moduleName)) return;

    if (!fedState.data.length) {
      updateModuleData(mwState.moduleLocalData);
      return;
    }

    //hard coded 0 as it cannot be more for this version
    const fedModuleSpecificData = {
      ...fedState.data[0][Utils.getFedKeyNameForModule(mwState.moduleName)],
    };
    //creating a newObject from fedModuleSpecificData with the key
    // as id and not index, so that it doesnt overwrite local data
    const newObj = Object.keys(fedModuleSpecificData).reduce((acc, key) => {
      acc[fedModuleSpecificData[key].id] = fedModuleSpecificData[key];
      return acc;
    }, {});
    const updatedData = { ...mwState.moduleLocalData, ...newObj };
    //applying the filters if selected
    const filteredData = filteredList(
      Object.values(updatedData),
      filterSelection
    );
    //applying the search if selected
    const filteredDataArr =
      searchField && searchTerm && filteredData
        ? filteredData.filter((item) =>
            searchField.some((field) =>
              item[field]?.toLowerCase().includes(searchTerm.toLowerCase())
            )
          )
        : filteredData;

    const filteredModuleData =
      filteredDataArr && Utils.arrayToObject(filteredDataArr, "id");
    const numberOfItems = filteredModuleData
      ? Object.keys(filteredModuleData).length
      : 0;

    setMwState((p) => ({
      ...p,
      moduleFederationData: newObj,
      moduleData: updatedData,
      filteredModuleData: filteredModuleData,
      pagination: {
        ...p.pagination,
        numberOfItems: numberOfItems,
        endIndex:
          numberOfItems < p.pagination.itemsPerPage
            ? numberOfItems
            : p.pagination.endIndex,
      },
    }));
  }, [fedState.version]);

  return null;
}
