import React, { useState, useEffect } from "react";

// Material-UI
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

// Components
import CustomTooltip from "@Components/CustomTooltip";

// Styles
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Client from "sccClient";
import Clock from "sccClock";
import Language from "sccLanguage";
import Maps from "sccMaps";


const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
  chips: {
    alignItems: "normal",
  },
}));

export default function ClientDetail(props) {
  const data = props.data;
  const [features, setFeatures] = useState();

  useEffect(() => {
    if (data.features?.length > 0) {
      Client.getFeatures().then((features) => {
        const newFeatures = data.features.map((id) => features[id]);
        setFeatures(Object.values(newFeatures));
      });
    }
  }, []);

  const classes = useStyles();

  return (
    <Grid container className={classes.detailsContainer}>
      <Grid item xs={12} md={12}>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Status")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.active ? "Active" : "Inactive"}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Company Name")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.company}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("City")}
          />
          <ListItemText md={6} className="itemTextSecond" primary={data.city} />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Country")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.country}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Email")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.email}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Start Date")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={
              data.start_timestamp
                ? Clock.formatTimestamp(data.start_timestamp)
                : "N/A"
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("End Date")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={
              data.expiry_timestamp
                ? Clock.formatTimestamp(data.expiry_timestamp)
                : "N/A"
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Devices Registered")}
          />
          <ListItemText
            md={3}
            className="itemTextSecond splitIn3"
            primary={data.devices.length}
          />
          <ListItemText
            md={3}
            className="itemTextFirst splitIn3"
            primary={
              <CustomTooltip
                title={Language.translate("Device Limit")}
                placement="top"
              >
                <span>{data.device_limit}</span>
              </CustomTooltip>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Users Registered")}
          />
          <ListItemText
            md={3}
            className="itemTextSecond splitIn3"
            primary={data.users.length}
          />
          <ListItemText
            md={3}
            className="itemTextFirst splitIn3"
            primary={
              <CustomTooltip
                title={Language.translate("User Limit")}
                placement="top"
              >
                <span>{data.user_limit}</span>
              </CustomTooltip>
            }
          />
        </ListItem>
        <ListItem className={classes.chips}>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Map Layers")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.map_layers.map((id) => {
              return (
                <Chip
                  key={id}
                  size="small"
                  label={Language.translate(`${Maps.get(id).title}`)}
                />
              );
            })}
          />
        </ListItem>
        <ListItem className={classes.chips}>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("Features")}
          />
          {features && (
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={features.map((feature) => (
                <Chip
                  key={feature.id}
                  size="small"
                  label={Language.translate(`${feature.title}`)}
                />
              ))}
            />
          )}
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("SA Wave Device Limit")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.sa_wave_limit}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("SA NORTAC ORION+ Device Limit")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.sa_orionPlus_limit}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            md={6}
            className="itemTextFirst splitIn3"
            primary={Language.translate("SA SCCT Device Limit")}
          />
          <ListItemText
            md={6}
            className="itemTextSecond"
            primary={data.sa_scct_limit}
          />
        </ListItem>
      </Grid>
    </Grid>
  );
}
