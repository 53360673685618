// External
import _ from "lodash";
import log from "loglevel";

// Styles
import theme from "@Styles/theme";

// Internal
import AlertMenu from "sccAlertMenu";
import DeviceDataDisplay from "../../device/components/DataDisplay";
import HermesGatewaysDetail from "../../hermes_gateways/body/HermesGatewaysDetail";
import Module from "sccModule";
import Permission from "sccPermission";
import UserSetting from "sccUserSetting";
import Utils from "sccUtils";
import Device from "sccDevice";

const alertColors = {
  Emergency: theme.palette.colors.red.main,
  Geofence: theme.palette.colors.orange.main,
  Speed: theme.palette.colors.purple.main,
  "Non-Report": theme.palette.colors.green.main,
  Cargo: theme.palette.alerts.cargo.main,
  Normal: theme.palette.colors.black.main,
  Vehicle: theme.palette.alerts.vehicle.main,
};

const alertPriorities = {
  Emergency: 1,
  Geofence: 2,
  Speed: 3,
  Cargo: 4,
  "Non-Report": 5,
  Vehicle: 6,
  Normal: 7,
};

const noAlertColor = theme.palette.colors.black.main;

/**
 * The Alert Class
 *
 * @class AlertModule
 */
class AlertModule extends Module.Module {
  constructor() {
    super({
      moduleName: "alert",
      getterSetter: ["AlertType", "CargoAlertType", "VehicleAlertType"],
    });
  }

  init($scope) {
    if (!Permission.verify("alarm", "view")) {
      log.debug("User is not permitted for Alarm module");
      return Promise.resolve();
    }

    this.$scope = $scope;
    this.hardResetting = false;
    this.hideAlert = false;

    return this.loadAlertType()
      .then(() => {
        return this.loadCargoAlertType();
      })
      .then(() => {
        return this.loadVehicleAlertType();
      })
      .then(() => {
        return super.init();
      });
  }

  /**
   * acknowledges alerts by list of IDs
   * @param {Array|Number} alertIds list of alert IDs
   */
  acknowledge(alertIds) {
    const $this = this;
    alertIds = _.concat([], alertIds);
    const options = {
      url: this.routeUrl + "/acknowledge",
      method: "POST",
      body: { alert_id: alertIds },
    };

    return Utils.httpRequestHandler(options)
      .then((response) => {
        $this.removeAlertPopupNotification(alertIds);

        // play/pause button disappear
        if (response.data?.result?.alert_type) {
          const alertType = response.data.result.alert_type.toLowerCase();

          const alertPlayIcon = document.getElementById(
            `alertPlayButton${alertType}`
          );

          alertPlayIcon.style.display = "none";
        }

        //  stop alert sound
        AlertMenu.pauseAlertSound();
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  /**
   * resets alerts by list of IDs
   * @param {Array|Number} ids list of alert IDs
   */
  reset(ids) {
    const $this = this;
    ids = _.concat([], ids);

    const options = {
      url: this.routeUrl + "/reset",
      method: "POST",
      body: { alert_id: ids },
    };

    return Utils.httpRequestHandler(options).then((response) => {
      $this.removeAlertPopupNotification(ids);
      return response.data.result;
    });
  }

  /**
   * removeds alert popup notification for list of alerts
   * @param {Array} alertIds list of alert IDs
   */
  removeAlertPopupNotification(alertIds) {
    const $this = this;
    _.map(alertIds, (alertId) => {
      const alert = $this.get(alertId);
      alert.kill && alert.kill(true);
    });
  }

  /**
   * overrides the onSocket method of module to refresh the device map
   * @param {*} event socket event
   * @param {*} data socket data
   */
  onSocket(event, data) {
    this.processSocketData(event, data);
    AlertMenu.toggleFavicon();
    // refreshing devices that have alerts on map
    const deviceOverlay = require("sccDeviceOverlay").default;
    _.each(data, (alert) => {
      if (deviceOverlay.initialized) {
        deviceOverlay.refresh(alert.device_id);
      }

      if (Device.initialized) {
        const device = Device.get(alert.device_id);
        if (Device.getHermesDevices().includes(device.type)) {
          const HermesGateways = require("sccHermesGateways").default;
          if (HermesGateways.initialized) {
            HermesGateways.updateDeviceAlert(device, alert);

            const hiddenHermesBtn = document.getElementById(
              "refreshHermesDetailAlertsList"
            );

            if (hiddenHermesBtn) {
              hiddenHermesBtn.click();
            }
          }
        }
      }
    });

    if (this.$scope) {
      this.$scope.safeApply();
    }

    const hiddenBtn = document.getElementById("hiddenAlertsBtn");
    if (hiddenBtn) {
      hiddenBtn.click();
    }
  }

  onSocketAdd(event, data) {
    super.onSocketAdd(event, data);
    _.map(data, (alert) => {
      this.showPopupNotification(alert);

      if (alert.type === "Emergency" && AlertMenu.menuInitialized) {
        // show play/pause button
        if (UserSetting.get("panic_audio")) {
          const alertType = this.getAlertType(
            alert.alert_type_id
          ).type.toLowerCase();

          const alertPlayIcon = document.getElementById(
            `alertPlayButton${alertType}`
          );

          if (alertPlayIcon) alertPlayIcon.style.display = "block";
        }
        AlertMenu.manageSoundForEmergencyAlerts(true);
      }
    });

    // refresh device alert bar display
    DeviceDataDisplay.updateFeatureSelected();
  }

  onSocketUpdate(event, data) {
    super.onSocketUpdate(event, data);

    // unshow play/pause buttton and stop alert sound
    _.map(data, (alert) => {
      if (alert.type === "Emergency" && AlertMenu.menuInitialized) {
        if (UserSetting.get("panic_audio")) {
          const alertType = this.getAlertType(
            alert.alert_type_id
          ).type.toLowerCase();

          const alertPlayIcon = document.getElementById(
            `alertPlayButton${alertType}`
          );

          alertPlayIcon.style.display = "none";
        }
      }
    });

    AlertMenu.manageSoundForEmergencyAlerts(false);

    // refresh device alert bar display
    DeviceDataDisplay.updateFeatureSelected();
  }

  onSocketDelete(event, data) {
    super.onSocketDelete(event, data);
    AlertMenu.manageSoundForEmergencyAlerts(false);

    // refresh device alert bar display
    DeviceDataDisplay.updateFeatureSelected();
  }

  /**
   * parses the socket data and calls the respective Module socket function, based on event (post, put, delete)
   * @param {*} event socket event
   * @param {*} data socket data
   */
  processSocketData(event, data) {
    var $this = this;
    var Profile = require("sccProfile").default;
    var userId = Profile.get("id");
    var Device = require("sccDevice").default;

    //coming from alert acknowledgement route
    if (event === "delete:/alert/:id") {
      $this.onSocketDelete(event, data);
    }
    //from plugins and alert routes
    else {
      var addAlerts = _.reduce(
        data,
        function (result, value, key) {
          //If alert does not exist in the list of alerts loaded in the session and it has no end time.
          //It must be treated as a new alert being sent from the plugin
          if (
            !$this.get(key) &&
            value.info.end_timestamp == null &&
            Device.get(value.device_id)
          ) {
            result[key] = value;
          }
          return result;
        },
        {}
      );

      var updateAlerts = _.reduce(
        data,
        function (result, value, key) {
          //If alert exists in the list of alerts loaded in the session.
          //It must updated with the latest info
          if ($this.get(key) && Device.get(value.device_id)) {
            result[key] = value;
          }
          return result;
        },
        {}
      );

      var deleteAlerts = _.reduce(
        data,
        function (result, value, key) {
          //If alert exists in the list of alerts loaded in the session and it has ended and also acked by session user
          //It must deleted from the list
          var sessionAlertData = $this.get(key);

          if (sessionAlertData && value.info.end_timestamp != null) {
            if (
              value.acknowledgements !== undefined &&
              // sometimes the alert will not be acknowleged by current user but by sub-user,
              // so here only check if this alert has been acknowleged
              !_.isEmpty(value.acknowledgements)
            ) {
              result.push({ id: key });

              //remove alert from data for update socket. To avoid multiple socket action for same alertId
              delete updateAlerts[key.toString()];
            }
          }
          return result;
        },
        []
      );

      if (!_.isEmpty(addAlerts)) {
        $this.onSocketAdd(event, addAlerts);
      }

      if (!_.isEmpty(updateAlerts)) {
        $this.onSocketUpdate(event, updateAlerts);
      }

      if (deleteAlerts.length > 0) {
        $this.onSocketDelete(event, deleteAlerts);
      }
    }
  }

  /**
   * loads alert types form the DB
   */
  loadAlertType() {
    var options = {
      url: this.routeUrl + "/type",
      method: "GET",
    };

    var $this = this;
    return Utils.httpRequestHandler(options).then((response) => {
      log.debug("AlertTypes", response.data.result);
      $this.setAlertType(response.data.result);
      return Promise.resolve();
    });
  }

  loadVehicleAlertType() {
    var options = {
      url: this.routeUrl + "/vehicle/type",
      method: "GET",
    };

    var $this = this;
    return Utils.httpRequestHandler(options).then((response) => {
      log.debug("VehicleAlertType", response.data.result);
      $this.setVehicleAlertType(response.data.result);
      return Promise.resolve();
    });
  }

  /**
   * loads Cargo alert types from the DB
   */
  loadCargoAlertType() {
    var options = {
      url: this.routeUrl + "/cargo/type",
      method: "GET",
    };

    var $this = this;
    return Utils.httpRequestHandler(options).then((response) => {
      log.debug("CargoAlertTypes", response.data.result);
      $this.setCargoAlertType(response.data.result);
      return Promise.resolve();
    });
  }

  /**
   * gets all alerts of a given type
   * @param {String} alertType alert type name
   * @return {Array} list of alerts of a given type
   */
  getTypeAlert(alertType) {
    const alertValues = _.values(this.get());
    const alertTypeId = this.getAlertTypeId(alertType);
    return _.filter(alertValues, { alert_type_id: alertTypeId });
  }

  getAlertColor(alertType) {
    const alertColor = alertColors[alertType];
    if (!alertColor)
      throw new Error("Could not find alert color for alert type " + alertType);

    return alertColor;
  }

  getAlertPriority(alertType) {
    const alertPriority = alertPriorities[alertType];
    if (!alertPriority)
      throw new Error(
        "Could not find alert priority for alert type " + alertType
      );

    return alertPriority;
  }

  //added a function to find the highest priority from array of alert types
  getHighestPriorityAlertType = (alertTypes) => {
    return alertTypes.reduce(
      (highest, type) =>
        alertPriorities[type] < alertPriorities[highest] ? type : highest,
      alertTypes[0]
    );
  };

  /**
   * gets the color code for the highest priority alert of a device
   * @param {Number} deviceId device id
   * @return {String} alert color code
   */
  getDeviceAlertColor(deviceId) {
    const $this = this;
    let alertColor = noAlertColor;
    _.each($this.getAlertType(), (alertType) => {
      const type = alertType.type;
      if ($this.getDeviceAlert(deviceId, type)) {
        alertColor = $this.getAlertColor(type);
        return false;
      }
    });

    return alertColor;
  }

  //get the alert color for federation devices using the alertTypes
  getHighestPriorityAlertColor = (alertTypes) => {
    return (
      this.getAlertColor(this.getHighestPriorityAlertType(alertTypes)) ||
      noAlertColor
    );
  };
  
  /**
   * gets all alerts of a given type for a given device
   * @param {Number} deviceId device id
   * @param {String} alertType alert type name
   * @return {Array} list of alerts of a given type for the given device
   */
  getDeviceAlert(deviceId, alertType) {
    const alertValues = _.values(this.get());
    const alertTypeId = this.getAlertTypeId(alertType);
    let deviceAlerts = _.filter(alertValues, {
      device_id: deviceId,
      alert_type_id: alertTypeId,
    });

    deviceAlerts = _.filter(deviceAlerts, (alert) => {
      return alert.info.end_timestamp == null;
    });
    return deviceAlerts.length ? deviceAlerts : null;
  }

  /**
   * checks whether or not a given user has acknowledge an alert
   * if no user is porvided, current user is used
   * @param {Object} alert alert object
   * @param {Number} userId id of user to be checked
   * @return {Boolean} ture if user has acknowledged and false otherwise
   */
  isUserAcknowledged(alert, userId) {
    const Profile = require("sccProfile").default;
    userId = userId || Profile.get("id");

    //keys of alert.acknowledgements onbject are string and need to be converted to Number/int array
    var ackIds = _.map(_.keys(alert.acknowledgements), Number);

    return _.indexOf(ackIds, userId) > -1;
  }

  /**
   * gets the id of alert for a given alert type
   * @param {String} alertType alert type name
   * @return {Number} alert type id
   */
  getAlertTypeId(alertType) {
    const alertTypeValues = _.values(this.getAlertType());
    const typeObject = _.find(alertTypeValues, { type: alertType });
    if (!typeObject) {
      log.warn("Could not find the ID for type " + alertType);
      return null;
    }

    return typeObject.id;
  }

  deleteAlert(userId) {
    var data = userId;
    return Utils.httpRequestHandler({
      method: "POST",
      url: Utils.apiUrlPrefix + "/alert",
      data: data,
    });
  }

  /**
   * Shows Popup notification for an alert
   * @param {Object} alert alert to be shown	 *
   */
  showPopupNotification(alert) {
    const $this = this;
    const alertType = $this.getAlertType(alert.alert_type_id).type;
    const UserSetting = require("sccUserSetting").default;

    if (!UserSetting.get("panic_popup")) return;
    if (alertType !== "Emergency") return;

    const Device = require("sccDevice").default;
    const Clock = require("sccClock").default;

    const title = "EMERGENCY ALERT";
    const message = "for device '" + Device.get(alert.device_id).name + "'";
    const subMessage =
      "Start Time: " + Clock.formatTimestamp(alert.info.start_timestamp);
    const data_acknowledgeAlertId = alert.id;
    const variant = "error";

    Utils.notify(title, message, subMessage, variant, data_acknowledgeAlertId);
  }
}

export default new AlertModule();
