import React, { useState, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import Popper from "@material-ui/core/Popper";
import CustomButton from "@Components/CustomButton";
import CustomSearch from "@Components/CustomSearch";
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomTooltip from "@Components/CustomTooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/styles";

// Internal
import Language from "sccLanguage";

function Filter(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { label, data, onChange, clearFilterSelection, filterSelection } =
    props;

  const [open, setOpen] = useState(Boolean(anchorEl));
  const [searchFilter, setSearchFilter] = useState("");
  var [filteredDeviceTypes, setFilteredDeviceTypes] = useState([]);

  const id = open ? "filterPopper" : undefined;

  const selection = _.isEmpty(filterSelection)
    ? { type_id: [] }
    : filterSelection;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const handleClickAwayClose = (e) => {
    if (!e.isTrusted) return false;
    handleClose();
  };
  const handleClearSearch = () => {
    setSearchFilter("");
    setFilteredDeviceTypes(Object.values(Device.getDeviceType()));
  };
  function updateFilteredSearchDataLocal(data) {
    setFilteredDeviceTypes(Object.values(data));
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      position: "absolute",
      minWidth: 300,
      maxWidth: 600,
      zIndex: 1000,
      maxHeight: 750,
    },
    button: {
      height: 30,
      width: 130,
      marginRight: 10,
      "& .MuiButton-endIcon": {
        marginLeft: 0,
      },
    },
    filter: {
      width: "100%",
      overflowY: "auto",
      maxHeight: 550,
    },

    filterContainer: {
      padding: 16,
    },

    title: {
      margin: "4px 0px",
    },

    labelItem: {
      display: "flex",
      alignItems: "center",
    },

    clearButton: {
      color: "#fff",
      backgroundColor: "#d9534f",
      borderColor: "#d43f3a",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "4px",
      borderRadius: 15,
      cursor: "pointer",
      maxWidth: 66,
      "& svg": {
        fontSize: 12,
      },
      "& span": {
        fontSize: 12,
      },
    },

    labelText: {
      fontWeight: 700,
    },

    topClearButton: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#d9534f",
      borderRadius: "50%",
      marginLeft: 4,
      "& .MuiSvgIcon-root": {
        color: theme.palette.colors.white.main,
        fontSize: "1rem",
      },
    },

    labelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 10,
      whiteSpace: "nowrap",
      lineHeight: "3px",
    },

    searchField: {
      padding: "2px 16px 2px 4px",
    },

    clearSearch: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },

    filterGrid: {
      width: 250,
    },

    noResult: {
      textAlign: "center",
    },
  }));
  const classes = useStyles();
  const Device = require("sccDevice").default;

  useEffect(() => {
    setFilteredDeviceTypes(Object.values(Device.getDeviceType()));
  }, []);

  const localDevices = Object.values(Device.get()).filter(
    (device) => !Boolean(device.federationServerId)
  );

  return (
    <React.Fragment>
      <CustomButton
        className={classes.button}
        onClick={handleClick}
        size="medium"
        color="command"
        variant="contained"
        startIcon={<FilterListIcon />}
        endIcon={<ArrowDropDownIcon />}
      >
        <CustomTooltip title={label} arrow placement="top">
          <div className={classes.labelContainer}>
            {label}
            {selection?.type_id?.length > 0 && (
              <>
                <span>({selection.type_id.length})</span>
                <span
                  className={classes.topClearButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    clearFilterSelection();
                  }}
                >
                  <CloseIcon />
                </span>
              </>
            )}
          </div>
        </CustomTooltip>
      </CustomButton>
      <Popper
        id={id}
        disablePortal={false}
        open={open}
        className={classes.root}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="bottom-start"
      >
        <Paper elevation={8} className={classes.filterContainer}>
          <ClickAwayListener onClickAway={handleClickAwayClose}>
            <Grid container>
              <Grid item container xs={10} alignItems="center">
                <Grid item xs={2}>
                  <h5 className={classes.labelText}>
                    {Language.translate("Filter")}
                  </h5>
                </Grid>

                {selection?.type_id?.length > 0 ? (
                  <Grid item container xs={10}>
                    <Grid
                      item
                      xs={5}
                      className={classes.clearButton}
                      onClick={clearFilterSelection}
                    >
                      <CloseIcon /> <span>{Language.translate("CLEAR")}</span>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              <Grid container item xs={2} justifyContent="flex-end">
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <h6 className={classes.title}>
                    {Language.translate("By Device Type")}{" "}
                    {selection?.type_id?.length > 0 ? (
                      <span>({selection.type_id.length})</span>
                    ) : null}
                  </h6>
                  {Device.getDeviceType() &&
                  Object.values(Device.getDeviceType()).length > 10 ? (
                    <div className={classes.searchField}>
                      <CustomSearch
                        id="txtSearch"
                        name="search"
                        placeholder={Language.translate("Search")}
                        className={classes.search}
                        value={searchFilter}
                        onChange={(e) => setSearchFilter(e.target.value)}
                        data={
                          Device.getDeviceType() &&
                          Object.values(Device.getDeviceType())
                        }
                        updateFilteredData={updateFilteredSearchDataLocal}
                        searchField={["title"]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              id="clearSearch"
                              onClick={handleClearSearch}
                              style={{ cursor: "pointer" }}
                            >
                              <ClearIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  ) : null}
                  <div className={classes.filter}>
                    <List>
                      {filteredDeviceTypes.map((type) => {
                        const exists = _.values(
                          _.filter(localDevices, {
                            type_id: type.id,
                          })
                        ).length;
                        return exists ? (
                          <ListItem key={type.id}>
                            <CustomCheckbox
                              name={type.title}
                              value={type.id}
                              checked={selection.type_id.indexOf(type.id) > -1}
                              onChange={(e) => onChange(e, "type_id", data)}
                              color="primary"
                            />
                            {type.title}
                            <span>
                              <Chip label={exists} size="small" />
                            </span>
                          </ListItem>
                        ) : null;
                      })}
                    </List>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </React.Fragment>
  );
}

export default Filter;
