import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

// Components
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { AppContext } from "../../../AppContext";
import { FederationDataContext } from "@Modules/federation_data/context/FederationDataContext";
import CustomDialogYesNo from "@Components/CustomDialogYesNo/CustomDialogYesNo";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import Language from "sccLanguage";
import Utils from "sccUtils";
import FedForm from "./FedForm";
import FedList from "./FedList";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
}));

const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;

export default function ModuleWrapperFedBody(props) {
  const [mwState] = useContext(ModuleWrapperContext);
  const [appState] = useContext(AppContext);
  const [fedState, setFedState] = useContext(FederationDataContext);
  const [openDetailsFor, setOpenDetailsFor] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogResult, setDialogResult] = useState(null);
  const fedServersOnly = getFedServersOnly(mwState.filteredModuleData);

  useEffect(() => {
    Utils.federationConnectionStatus().then((result) => {
      setFedState((p) => ({
        ...p,
        federationConnection: result,
      }));
    });
  }, []);

  const checkStatus = async () => {
    const result = await Utils.federationConnectionStatus();
    console.log("checklogin status", result);
  };

  const handleFederationConnection = async (data) => {
    let action = null;
    const amIConnected =
      fedState.federationConnection?.federationServerId === data.id;
    if (amIConnected) {
      action = "disconnect";
    } else if (_.isEmpty(fedState.federationConnection)) {
      action = "connect";
    } else {
      const result = await showDialogWithConfirmation();
      if (result === "yes") {
        console.log("Proceeding with connection...");
        action = "connect";
      } else {
        console.log("Action was cancelled.");
        return false;
      }
    }
    const options = {
      url: mwState.routeUrl + "/" + action,
      method: "POST",
      data,
    };
    try {
      let headers = {
        "Content-Type": "application/json",
      };
      if (process.env.REACT_APP_IS_INDEPENDENT_DEV) {
        headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true",
        };
      }
      const response = await fetch(options.url, {
        method: options.method,
        headers: headers,
        credentials: "include",
        body: JSON.stringify(options.data),
      });
      const responseData = await response.json();

      if (responseData.error) {
        appState.displaySnackbarMessage({
          title: "Federation",
          message: responseData.error,
          variant: "error",
        });
      } else {
        appState.displaySnackbarMessage({
          title: "Federation",
          message: Language.translate(
            (amIConnected ? "Disconnection" : "Connection") + " was successful"
          ),
          subMessage: null,
          variant: "success",
        });
        setFedState((p) => ({
          ...p,
          federationConnection: amIConnected
            ? {}
            : { federationServerId: data.id },
        }));
      }
      return responseData;
    } catch (error) {
      appState.displaySnackbarMessage({
        message: Language.translate("Error") + ": " + error,
        variant: "error",
      });
    }
  };

  const showDialogWithConfirmation = () => {
    return new Promise((resolve) => {
      setShowDialog(true);
      const onYesAction = () => {
        setShowDialog(false);
        resolve("yes");
      };
      const onNoAction = () => {
        setShowDialog(false);
        resolve("no");
      };
      setDialogResult({
        onYesAction,
        onNoAction,
      });
    });
  };

  const filteredModuleData = mwState.convertAndSort(
    fedServersOnly,
    mwState.searchField
  );

  function getFedServersOnly(filteredModuleData) {
    return Object.fromEntries(
      Object.entries(filteredModuleData).filter(
        ([_, item]) => item.typeID === 2
      )
    );
  }

  const handleClick = (id) => {
    openDetailsFor !== id ? setOpenDetailsFor(id) : setOpenDetailsFor(null);
    checkStatus();
  };

  const classes = useStyles();

  return (
    <>
      {mwState.wrapperDisplayMode === MW_LIST_MODE ? (
        <ul className={classes.listContainer}>
          {!_.isEmpty(filteredModuleData) ? (
            filteredModuleData
              .slice(mwState.pagination.startIndex, mwState.pagination.endIndex)
              .map((data) => {
                return (
                  <FedList
                    key={data.id}
                    data={data}
                    handleClick={handleClick}
                    handleFederationConnection={handleFederationConnection}
                    openDetailsFor={openDetailsFor}
                  />
                );
              })
          ) : (
            <div className="noResults">{Language.translate("No Results!")}</div>
          )}
        </ul>
      ) : (
        <FedForm handleFederationConnection={handleFederationConnection} />
      )}
      <CustomDialogYesNo
        open={showDialog}
        text="This action will disconnect the now connected server. Do you wish to
          continue?"
        onYesAction={dialogResult?.onYesAction}
        onNoAction={dialogResult?.onNoAction}
      />
    </>
  );
}
