import React, { useState, useContext } from "react";

// External
import { useFormikContext } from "formik";
import _ from "lodash";

// Material-UI
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import MenuItem from "@material-ui/core/MenuItem";

// Internal
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { UserSettingContext } from "../context/UserSettingContext.js";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import CustomSwitch from "@Components/CustomSwitch";
import CustomDialog from "@Components/CustomDialog";
import Language from "sccLanguage";
import Permission from "sccPermission";
import Clock from "sccClock";

function GeneralSettings(props) {
  const [mwState] = useContext(ModuleWrapperContext);
  const { setUserSettingData } = useContext(UserSettingContext);

  const [languages, setLanguages] = useState({});
  const [changeLanguageWarningOpen, setChangeLanguageWarningOpen] =
    useState(false);
  const [languageEvent, setLanguageEvent] = useState({});

  Language.getLanguages().then((result) => setLanguages(result));

  let langData = _.map(languages, function (language) {
    // Change title of Languages to their language
    if (language.name === "Spanish") {
      language.name = "Español";
    }
    if (language.name === "Francais") {
      language.name = "Français";
    }
    return { value: language.id, title: language.name };
  });
  langData = _.sortBy(langData, function (language) {
    return language.title;
  });
  const languageSetting = {
    languageTag: "Language",
    settingKey: "language_id",
    optionData: _.isEmpty(langData)
      ? [{ value: 1, title: "English" }]
      : langData,
  };

  const clockFormatSetting = {
    languageTag: "Clock Format",
    settingKey: "clock_format",
    optionData: [
      { value: 12, title: "12 " + Language.translate("Hour") },
      { value: 24, title: "24 " + Language.translate("Hours") },
    ],
  };

  const speedFormatSetting = {
    languageTag: "Speed Format",
    settingKey: "speed_format",
    optionData: [
      { value: "KPH", title: Language.translate("KPH") },
      { value: "MPH", title: Language.translate("MPH") },
      { value: "KNOTS", title: Language.translate("KNOTS") },
    ],
  };

  const timeZone2Setting = {
    languageTag: "Time Zone",
    titleAppend: " 2",
    settingKey: "time_zone_2",
    optionData: _.map(Clock.getAllZones(), function (zone) {
      var title = Clock.getZoneTitle(zone);
      return { value: zone.name, title: title };
    }),
  };

  const timeZone3Setting = {
    languageTag: "Time Zone",
    titleAppend: " 3",
    settingKey: "time_zone_3",
    optionData: _.map(Clock.getAllZones(), function (zone) {
      var title = Clock.getZoneTitle(zone);
      return { value: zone.name, title: title };
    }),
  };

  function updateAndStay(e, data, routeUrl, message) {
    handleFormInput(e);
    let val = e.target.value;
    if (val === "false") val = true;
    else if (val === "true") val = false;
    data = { ...data, [e.target.name]: val };
    updateModuleData(data, routeUrl, message).then((data) => {
      if (!data.error && data.result !== null) {
        setUserSettingData(data.result);
      }
    });
  }

  // inside language change warning dialog, if user click OK, refresh the page with the language
  function handleLanguageOnOkClick() {
    updateAndStay(languageEvent, moduleItemData, mwState.routeUrl, message);
  }

  const formik = useFormikContext();
  const updateModuleData = mwState.updateModuleData;

  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;

  const message = {
    title: Language.translate("Settings"),
    text: Language.translate("Successfully Updated"),
    noRefresh: true,
  };

  return (
    <div className="SettingsBody">
      {/* this custom dialog is used to display warning message if user want to change language */}
      <>
        <CustomDialog
          text={Language.translate(
            "Your session will have to be reloaded to apply the changes. Are you sure you want to continue?"
          )}
          open={changeLanguageWarningOpen}
          onCancelAction={() => setChangeLanguageWarningOpen(false)}
          onOkAction={() => handleLanguageOnOkClick()}
        />
      </>
      <h4
        style={{
          fontWeight: "normal",
          fontSize: "24px",
          marginTop: "0px",
          marginBottom: "10px",
        }}
      >
        {Language.translate("General Settings")}
      </h4>
      <ul className="listing">
        {!Permission.verify("client", "view") && (
          <ListItem className="listItem">
            <ListItemText primary={Language.translate("Emergency Audio")} />
            <ListItemSecondaryAction>
              <CustomSwitch
                name="panic_audio"
                value={Boolean(moduleItemData.panic_audio)}
                onChange={(e) =>
                  updateAndStay(e, moduleItemData, mwState.routeUrl, message)
                }
                checked={Boolean(moduleItemData.panic_audio)}
                color="primary"
                labelPlacement="top"
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {!Permission.verify("client", "view") && (
          <ListItem className="listItem">
            <ListItemText
              primary={Language.translate("Emergency Alert Pop Up")}
            />
            <ListItemSecondaryAction>
              <CustomSwitch
                name="panic_popup"
                value={Boolean(moduleItemData.panic_popup)}
                onChange={(e) =>
                  updateAndStay(e, moduleItemData, mwState.routeUrl, message)
                }
                checked={Boolean(moduleItemData.panic_popup)}
                color="primary"
                labelPlacement="top"
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {!Permission.verify("client", "view") && (
          <ListItem className="listItem">
            <ListItemText
              primary={Language.translate("Display Federation Data")}
            />
            <ListItemSecondaryAction>
              <CustomSwitch
                name="display_federation_data"
                value={Boolean(moduleItemData.display_federation_data)}
                onChange={(e) =>
                  updateAndStay(e, moduleItemData, mwState.routeUrl, message)
                }
                checked={Boolean(moduleItemData.display_federation_data)}
                color="primary"
                labelPlacement="top"
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {!Permission.verify("client", "view") && (
          <ListItem className="listItem">
            <ListItemText
              primary={Language.translate(languageSetting.languageTag)}
            />
            <ListItemSecondaryAction>
              <CustomDropdown
                id="language"
                name="language_id"
                className="dropdown"
                value={moduleItemData.language_id || 1}
                // open language warning dialog
                onChange={(e) => {
                  setChangeLanguageWarningOpen(true);
                  // hold language event value
                  setLanguageEvent(e);
                }}
              >
                {languageSetting.optionData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </CustomDropdown>
            </ListItemSecondaryAction>
          </ListItem>
        )}
        <ListItem className="listItem">
          <ListItemText
            primary={Language.translate(clockFormatSetting.languageTag)}
          />
          <ListItemSecondaryAction>
            <CustomDropdown
              id="clock_format"
              name="clock_format"
              className="dropdown"
              value={moduleItemData.clock_format || 12}
              onChange={(e) =>
                updateAndStay(e, moduleItemData, mwState.routeUrl, message)
              }
            >
              {clockFormatSetting.optionData.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </CustomDropdown>
          </ListItemSecondaryAction>
        </ListItem>
        {!Permission.verify("client", "view") && (
          <ListItem className="listItem">
            <ListItemText
              primary={Language.translate(speedFormatSetting.languageTag)}
            />
            <ListItemSecondaryAction>
              <CustomDropdown
                id="speed_format"
                name="speed_format"
                className="dropdown"
                value={moduleItemData.speed_format || "KPH"}
                onChange={(e) =>
                  updateAndStay(e, moduleItemData, mwState.routeUrl, message)
                }
              >
                {speedFormatSetting.optionData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </CustomDropdown>
            </ListItemSecondaryAction>
          </ListItem>
        )}
        <ListItem className="listItem">
          <ListItemText
            primary={`${Language.translate(timeZone2Setting.languageTag)} 2`}
          />
          <ListItemSecondaryAction>
            <CustomDropdown
              id="time_zone_2"
              name="time_zone_2"
              className="dropdown"
              value={moduleItemData.time_zone_2 || "UTC"}
              onChange={(e) =>
                updateAndStay(e, moduleItemData, mwState.routeUrl, message)
              }
            >
              {timeZone2Setting.optionData.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </CustomDropdown>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem className="listItem">
          <ListItemText
            primary={`${Language.translate(timeZone2Setting.languageTag)} 3`}
          />
          <ListItemSecondaryAction>
            <CustomDropdown
              id="time_zone_3"
              name="time_zone_3"
              className="dropdown"
              value={moduleItemData.time_zone_3 || "UTC"}
              onChange={(e) =>
                updateAndStay(e, moduleItemData, mwState.routeUrl, message)
              }
            >
              {timeZone3Setting.optionData.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </CustomDropdown>
          </ListItemSecondaryAction>
        </ListItem>
      </ul>
    </div>
  );
}

export default GeneralSettings;
