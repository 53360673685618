import React, { useContext, useState } from "react";

// External
import _ from "lodash";

// Material-UI
import Popper from "@material-ui/core/Popper";
import CustomButton from "@Components/CustomButton";
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomTooltip from "@Components/CustomTooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import LeakAddIcon from "@material-ui/icons/LeakAdd";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/styles";

// Internal
import Language from "sccLanguage";
import { ModuleWrapperContext } from "../ModuleWrapper/ModuleWrapperContext";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    minWidth: 200,
    maxWidth: 250,
    zIndex: 1000,
    maxHeight: 750,
  },
  buttonFed: {
    height: 30,
    marginRight: 10,
    "& .MuiButton-endIcon": {
      marginLeft: 0,
    },
  },
  filter: {
    width: "100%",
    overflowY: "auto",
    maxHeight: 550,
  },
  filterContainer: {
    padding: 16,
  },
  title: {
    margin: "10px 0px",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 10,
    whiteSpace: "nowrap",
    lineHeight: "3px",
  },
  topClearButton: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#d9534f",
    borderRadius: "50%",
    marginLeft: 4,
    "& .MuiSvgIcon-root": {
      color: theme.palette.colors.white.main,
      fontSize: "1rem",
    },
  },
  clearButton: {
    color: "#fff",
    backgroundColor: "#d9534f",
    borderColor: "#d43f3a",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 4,
    marginTop: 10,
    borderRadius: 15,
    cursor: "pointer",
    maxWidth: 75,
    "& svg": {
      fontSize: 12,
    },
    "& span": {
      fontSize: 12,
    },
  },
}));

function CustomFilter(props) {
  const refPicker = React.useRef();
  const {
    name,
    label,
    originalData,
    filterSelection,
    onChange,
    filterField,
    ...other
  } = props;
  const [open, setOpen] = useState(false);
  const id = open ? "filterPopperFed" : undefined;

  const handleClick = (event) => {
    setOpen(Boolean(refPicker.current));
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickAwayClose = (e) => {
    if (!e.isTrusted) return false;
    handleClose();
  };

  const hasFederationServerId =
    filterSelection.hasOwnProperty("federationServerId") &&
    Array.isArray(filterSelection.federationServerId) &&
    filterSelection.federationServerId.length > 0;

  const classes = useStyles();

  return (
    <div ref={refPicker}>
      <CustomButton
        className={classes.buttonFed}
        onClick={handleClick}
        size="medium"
        color="command"
        variant="contained"
        startIcon={<LeakAddIcon />}
        endIcon={<ArrowDropDownIcon />}
        {...other}
      >
        <CustomTooltip title={label} arrow placement="top">
          <div className={classes.labelContainer}>
            {label}
            {hasFederationServerId && (
              <>
                <span
                  className={classes.topClearButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(null, null, originalData);
                  }}
                >
                  <CloseIcon />
                </span>
              </>
            )}
          </div>
        </CustomTooltip>
      </CustomButton>
      <Popper
        id={id}
        disablePortal={false}
        open={open}
        className={classes.root}
        anchorEl={refPicker.current}
        onClose={handleClose}
        placement="bottom-start"
      >
        <Paper elevation={8} className={classes.filterContainer}>
          <ClickAwayListener onClickAway={handleClickAwayClose}>
            <Grid container>
              <Grid container item xs={12}>
                <Grid container item xs={7}>
                  <h6 className={classes.title}>
                    {Language.translate(filterField.title)}
                  </h6>
                </Grid>
                <Grid
                  item
                  xs={3}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(null, null, originalData);
                  }}
                >
                  {hasFederationServerId && (
                    <span className={classes.clearButton}>
                      <CloseIcon /> <span>{Language.translate("CLEAR")}</span>
                    </span>
                  )}
                </Grid>
                <Grid container item xs={2} alignItems="flex-start">
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid container item xs={12}>
                  <div className={classes.filter}>
                    <List>
                      {filterField.list?.map((chk) => {
                        const exists = _.values(
                          _.filter(Object.values(originalData), (item) => {
                            return item[filterField.id] === chk[filterField.id];
                          })
                        );
                        return exists.length ? (
                          <ListItem key={"_" + chk[filterField.id]}>
                            <CustomCheckbox
                              name={filterField.title}
                              value={chk[filterField.id]}
                              checked={
                                filterSelection[filterField.id]
                                  ? filterSelection[filterField.id].includes(
                                      chk[filterField.id]
                                    )
                                  : false
                              }
                              onChange={(e) =>
                                onChange(e, filterField.id, originalData)
                              }
                              color="primary"
                            />
                            {chk[filterField.displayField]}
                            <span>
                              <Chip
                                style={{ marginLeft: 5 }}
                                label={exists.length}
                                size="small"
                              />
                            </span>
                          </ListItem>
                        ) : null;
                      })}
                    </List>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
}
export default CustomFilter;
