import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { useFormikContext } from "formik";

// Material-UI
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Badge from "@material-ui/core/Badge";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Chip from "@material-ui/core/Chip";
import { makeStyles, alpha } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

// Internal
import Language from "sccLanguage";
import User from "sccUser";
import Device from "sccDevice";
import AddressBookMenu from "sccAddressBookMenu";
import CustomIcon from "@Components/CustomIcon";
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomListItemCollapse from "@Components/CustomListItemCollapse";
import CustomPagination from "@Components/CustomPagination";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomTextField from "@Components/CustomTextField";
import CustomAssetWrapper from "@Components/CustomAssetWrapper";
import CustomItemCount from "@Components/CustomItemCount";
import CustomTooltip from "@Components/CustomTooltip";
import { CustomAssetSelectorContext } from "../../../reusable-components/CustomAssetWrapper/CustomAssetSelectorContext";
import { moduleForm } from "@Styles/ModuleForm";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  abAddForm: {
    margin: "15px",
    paddingBottom: "10px",
    "& .CustomItemCountWrap": {
      height: "70px",
      justifyContent: "flex-end",
      "& .CustomItemCount": {
        margin: "8px",
        padding: "5px",
      },
    },
    "& .ABRequiredFields": {
      textAlign: "right",
      margin: "0",
    },
    "& .orion-chip-padding": {
      paddingLeft: "8px",
      "& . orion-chip": {
        backgroundColor: alpha(theme.palette.colors.black.main, 0.5),
        color: theme.palette.colors.white.main,
      },
    },
  },

  orionDeviceContainer: {
    display: "flex",
    alignItems: "center",
  },

  orionDevices: {
    width: 140,
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  orionSearch: {
    padding: "8px 8px 0px 8px",
  },

  orionMode: {
    "& div span": {
      fontSize: 10,
    },
  },

  subscribersAndMembersContainer: {
    marginTop: 4,
  },

  userNameContainer: {
    display: "flex",
    alignItems: "center",
  },

  noResult: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  userNameIcon: {
    "& svg": {
      fontSize: 10,
    },
  },

  userNameText: {
    paddingLeft: 8,
    maxWidth: 200,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function ABForm(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const formik = useFormikContext();
  const moduleItemData = formik.values;
  // to hold subscriber id/ids
  const [subscribers, setSubscribers] = useState([]);
  const [users, setUsers] = useState([]);
  const [membersCount, setMembersCount] = useState(0);
  // these states are used to search subscriber Orion Devices
  const [searchedDevices, setSearchedDevices] = useState([]);
  const [allOrionDevicesInfo, setAllOrionDevicesInfo] = useState([]);
  const [searchText, setSearchText] = useState("");

  // for Tabs use
  const [value, setValue] = useState(0);

  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  function onPageChange(currentPage, startIndex, endIndex) {
    //change list desplay to show the current index of records
    setMwState((p) => ({
      ...p,
      pagination: {
        ...p.pagination,
        currentPage: currentPage,
        startIndex,
        endIndex,
      },
    }));
  }

  const minimizeSelection = assetSelectorState.minimizeSelection;

  const moduleItemDataAB = {
    title: "",
    note: "",

    members: {
      groups: [],
      devices: [],
      users: [],
    },
    subscribers: {
      groups: [],
      devices: [],
    },
  };

  useEffect(() => {
    if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
      setMwState((p) => ({
        ...p,
        moduleItemData: { ...moduleItemDataAB, ...mwState.moduleItemData },
        moduleItemDataOriginal: {
          ...moduleItemDataAB,
          ...mwState.moduleItemData,
        },
      }));

      //used as a fix to set the initial state of the AS, as otherwise the AS needs to rerender before the values show up as selected
      if (mwState.moduleItemData.id) {
        assetSelectorState.setStateForSelection(
          assetSelectorState.maximizeSelection(mwState.moduleItemData.members),
          "members"
        );
      } else {
        assetSelectorState.setStateForSelection(
          { groups: [], devices: [] },
          "members"
        );
      }

      setSubscribers(
        Device.getAvaiableDevices(
          mwState.moduleItemData?.subscribers?.devices
        ) || []
      );
      setUsers(mwState.moduleItemData?.members?.users || []);

      //  get Orion Devices that displayed in Subscribers, only display unselected Devices or devices selected in this AB
      const orionDevices = Object.values(Device.get()).filter((item) => {
        return (
          !item.federationServerId &&
          (item.type === "NORTAC Orion" || item.type === "NORTAC ORION+") &&
          (!AddressBookMenu.isDeviceAnAddressBookSubscriber(item.id) ||
            mwState.moduleItemData?.subscribers?.devices?.includes(item.id))
        );
      });

      if (orionDevices?.length > 0) {
        setAllOrionDevicesInfo(orionDevices);
        setSearchedDevices(orionDevices);
      }
    }
  }, [mwState.wrapperDisplayMode]);

  //this useeffect is called on change in selection of AS and sets the members & subscribers for formik
  useEffect(() => {
    // The value of members need to be locally written
    // to keep them seperate from the AssetSelector and Formik values
    if (assetSelectorState.selection["members"]) {
      setMembersCount(
        Device.getAvaiableDevices(
          assetSelectorState.selection["members"]?.devices || []
        ).length
      );
      formik.setFieldValue(
        "members.groups",
        minimizeSelection(assetSelectorState.selection["members"]).groups
      );

      formik.setFieldValue(
        "members.devices",
        minimizeSelection(assetSelectorState.selection["members"]).devices
      );
    }
  }, [assetSelectorState.selection]);

  //  handle tab switch
  function handleTabs(e, val) {
    setValue(val);
  }

  const getOrionDeviceMode = (device) => {
    const deviceTypeFeatures = Device.getDeviceType(device.type_id);
    const type = device.type;
    const mode = deviceTypeFeatures.modes[device.mode]?.title || "N/A";

    if (mode === "Standalone" || mode === "Open Protocol") {
      if (type === "NORTAC Orion" || type === "NORTAC ORION+") {
        return Language.translate(`${type} ${mode}`);
      } else {
        return mode;
      }
    }
  };

  //  use debounce to avoid too many re-renders
  const handleOrionDeviceSearch = _.debounce((value) => {
    const searchText = value;
    if (searchText !== "") {
      const newSearchedDevices = allOrionDevicesInfo.filter(
        (deviceInfo) =>
          deviceInfo.name.toLowerCase().includes(searchText.toLowerCase()) ||
          deviceInfo.imei.includes(searchText.toLowerCase())
      );
      setSearchedDevices(newSearchedDevices);
    } else {
      setSearchedDevices(allOrionDevicesInfo);
    }
  }, 300);

  const onOrionDeviceSearch = (e) => {
    setSearchText(e.target.value);
    handleOrionDeviceSearch(e.target.value);
  };

  const handleSubscriberClick = (e) => {
    let newSubscribers = [];
    const value = Number(e.target.value);
    if (subscribers.includes(value)) {
      newSubscribers = subscribers.filter((x) => x !== value);
    } else {
      newSubscribers = subscribers.concat([value]);
    }

    formik.setFieldValue("subscribers.devices", newSubscribers);
    setSubscribers(newSubscribers);
  };

  const handleUserClick = (e) => {
    let newUsers = [];
    const value = Number(e.target.value);
    if (users.includes(value)) {
      newUsers = users.filter((x) => x !== value);
    } else {
      newUsers = users.concat([value]);
    }

    formik.setFieldValue("members.users", newUsers);
    setUsers(newUsers);
  };

  const classes = useStyles();

  const allUsers = User.get();

  return (
    <div className={classes.abAddForm}>
      <Grid container spacing={1} direction="column">
        <Grid
          container
          item
          className="CustomItemCountWrap"
          xs={12}
          direction="row"
        >
          <CustomItemCount
            title={Language.translate("Subscribers")}
            count={subscribers.length}
          />{" "}
          {/* count should be assetSelectorState.selectionDevices.length */}
          <CustomItemCount
            title={Language.translate("Members")}
            count={membersCount + users.length}
          />{" "}
          {/* count should be assetSelectorState.selectionDevices.length */}
        </Grid>
        <p className="ABRequiredFields">
          {"*" + Language.translate("Required fields")}
        </p>
      </Grid>
      <Grid container item direction="column" spacing={1}>
        <Grid item xs={12}>
          <CustomTextField
            label={`${Language.translate("Title")}*`}
            value={String(moduleItemData.title)}
            name="title"
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label={`${Language.translate("Note")}*`}
            value={String(moduleItemData.note)}
            name="note"
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={formik.touched.note && Boolean(formik.errors.note)}
            helperText={formik.touched.note && formik.errors.note}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        className={classes.subscribersAndMembersContainer}
      >
        <Grid container item xs={6} direction="column">
          <CustomListItemCollapse
            title={Language.translate("Subscribers").toUpperCase()}
            color="primary"
            collapse={true}
            count={subscribers.length}
          >
            <div className={classes.orionSearch}>
              <CustomTextField
                label=""
                value={searchText}
                fullWidth={true}
                onChange={onOrionDeviceSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <List>
              {searchedDevices.length > 0 ? (
                searchedDevices.map((item) => (
                  <ListItem key={item.id}>
                    <CustomCheckbox
                      name="deviceChecked"
                      value={item.id}
                      onChange={handleSubscriberClick}
                      color="primary"
                      checked={subscribers.includes(Number(item.id))}
                    />
                    <div className={classes.orionDeviceContainer}>
                      <CustomTooltip title={item.name} arrow placement="top">
                        <div className={classes.orionDevices}>{item.name}</div>
                      </CustomTooltip>

                      <span className={classes.orionMode}>
                        <Chip
                          className="orion-chip"
                          label={getOrionDeviceMode(item)}
                          size="small"
                        />
                      </span>
                    </div>
                  </ListItem>
                ))
              ) : (
                <ListItem className={classes.noResult}>
                  {Language.translate("There are no devices in Subscribers")}
                </ListItem>
              )}
            </List>
            <Grid item align="right" className="pagination" xs={9}>
              <CustomPagination
                pagination={mwState.pagination}
                onPageChange={onPageChange}
              />
            </Grid>
          </CustomListItemCollapse>
        </Grid>
        <Grid container item xs={6} direction="column">
          <CustomListItemCollapse
            className="listItem"
            title={Language.translate("Members").toUpperCase()}
            color="primary"
            collapse={true}
            count={membersCount + users.length}
          >
            <Tabs
              value={value}
              onChange={handleTabs}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab
                label={Language.translate("Users")}
                style={{ fontSize: 9 }}
                icon={
                  <Badge color="primary" badgeContent={users.length || 0}>
                    <CustomIcon style={{ fontSize: 9 }} id="icon-laptop" />
                  </Badge>
                }
              />
              <Tab
                label={Language.translate("Assets")}
                style={{ fontSize: 9 }}
                icon={
                  <Badge color="primary" badgeContent={membersCount}>
                    <CustomIcon style={{ fontSize: 9 }} id="icon-group" />
                  </Badge>
                }
              />
            </Tabs>
            {value === 0 && (
              <List>
                {allUsers &&
                  _.sortBy(Object.values(allUsers), (item) =>
                    item.first_name.toLowerCase()
                  ).map((item) => (
                    <ListItem key={item.id}>
                      <CustomCheckbox
                        name="checked"
                        value={item.id}
                        onChange={handleUserClick}
                        color="primary"
                        checked={users.includes(Number(item.id))}
                      />

                      {item.username ? (
                        <CustomTooltip title={item.username} placement="top">
                          <div className={classes.userNameContainer}>
                            <div className={classes.userNameIcon}>
                              <CustomIcon id={`icon-laptop`} fontSize="small" />
                            </div>
                            <div className={classes.userNameText}>
                              {item.first_name + " " + item.last_name}
                            </div>
                          </div>
                        </CustomTooltip>
                      ) : (
                        <div className={classes.userNameContainer}>
                          <div className={classes.userNameIcon}>
                            <CustomIcon id={`icon-laptop`} fontSize="small" />
                          </div>
                          <div className={classes.userNameText}>
                            {item.first_name + " " + item.last_name}
                          </div>
                        </div>
                      )}
                    </ListItem>
                  ))}
              </List>
            )}
            {value === 1 && (
              <CustomAssetWrapper
                openMain={true}
                editMode={true}
                selection={moduleItemData.members}
                showDeviceSearchField={false}
                name="members"
              />
            )}
          </CustomListItemCollapse>
        </Grid>
      </Grid>
    </div>
  );
}
