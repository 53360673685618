import React, { useContext } from "react";

// Material-UI
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, alpha } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MyLocationIcon from "@material-ui/icons/MyLocation";

// Components
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomIcon from "@Components/CustomIcon";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import Device from "sccDevice";
import GeofenceDetail from "./GeofenceDetail";
import GeofenceOverlay from "sccGeofenceOverlay";
import Language from "sccLanguage";
import GeofenceSetting from "@Modules/geofence/scripts/GeofenceSetting";
import _ from "lodash";
import GeofenceIcon from "../components/GeofenceIcon";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  titleIcon: {
    display: "inline-flex",
    paddingLeft: 7,
    color: alpha(theme.palette.colors.black.main, 0.54),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
  },
  inclusiveCircle: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.geofence.greenIcon.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    borderRadius: "50%",
  },
  exclusiveCircle: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.geofence.redIcon.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    borderRadius: "50%",
    transform: "rotate(-45deg)",
  },
  inclusivePath: {
    width: 20,
    height: 0,
    backgroundColor: theme.palette.geofence.greenIcon.dark,
    border: `1px solid ${theme.palette.geofence.greenIcon.dark}`,
    transform: "rotate(-45deg)",
  },
  exclusivePath: {
    width: 20,
    height: 0,
    backgroundColor: theme.palette.geofence.redIcon.dark,
    border: `1px solid ${theme.palette.geofence.redIcon.dark}`,
    transform: "rotate(-45deg)",
  },
  inclusiveRec: {
    width: 20,
    height: 10,
    backgroundColor: theme.palette.geofence.greenIcon.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
  },
  exclusiveRec: {
    width: 20,
    height: 10,
    backgroundColor: theme.palette.geofence.redIcon.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
  },
  inclusivePolygon: {
    position: "relative",
    height: 20,
    width: 11.55,
    backgroundColor: theme.palette.geofence.greenIcon.main,
    margin: "5.77px 0px",
    "&::before": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      right: "100%",
      borderRight: `5.77px solid ${theme.palette.geofence.greenIcon.main}`,
    },
    "&::after": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      left: "100%",
      borderLeft: `5.77px solid ${theme.palette.geofence.greenIcon.main}`,
    },
  },
  exclusivePolygon: {
    position: "relative",
    height: 20,
    width: 11.55,
    backgroundColor: theme.palette.geofence.redIcon.main,
    margin: "5.77px 0px",
    "&::before": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      right: "100%",
      borderRight: `5.77px solid ${theme.palette.geofence.redIcon.main}`,
    },
    "&::after": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      left: "100%",
      borderLeft: `5.77px solid ${theme.palette.geofence.redIcon.main}`,
    },
  },
  itemFederationText: {
    color: theme.palette.colors.gray.veryDark,
  },
  itemFederationTextServerName: {
    fontStyle: "italic",
  },
}));

export default function GeofenceList(props) {
  const data = props.data;
  const [mwState] = useContext(ModuleWrapperContext);
  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;
  const federationData = data.federationServerId;
  const isShowSyncIcon =
    Device.getAvaiableDevices(data.sync?.devices)?.length > 0;

  const zoomToCoord = () => {
    if (data.id) {
      GeofenceOverlay.centerAndZoomTo(data.id);
    }
  };

  // When listItem is click handleColourInfo checks if the geofence has an active colour id, if true, merges colour hex and colour label to data obj
  function handleColourInfo() {
    let geoType = data.inclusive ? "inclusive" : "exclusive";
    let emptyObjCheck = _.isEmpty(GeofenceSetting.get(geoType + "_palette"));

    if (!emptyObjCheck) {
      let usedPalette = JSON.parse(GeofenceSetting.get(geoType + "_palette"));
      let index = _.findIndex(usedPalette, { id: data.custom_colour_id });
      //_.findIndex returns -1 when no indecies were found
      let noneFound = -1;

      if (index !== noneFound) {
        const entry = usedPalette[index];
        data.hex = entry.hex;
        data.label = entry.label;
      }
    }
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={`${classes.listItem} ${data.active && classes.enabled}`}
        key={data.id}
        onClick={() => {
          handleClick(data.id);
          handleColourInfo();
        }}
      >
        <ListItemIcon className={classes.itemIcon}>
          <CustomTooltip title={data.title} placement="left">
            <GeofenceIcon geofence={data} isFederationData={federationData} />
          </CustomTooltip>
        </ListItemIcon>
        <ListItemText
          id={data.id}
          className={
            federationData
              ? classes.itemFederationText
              : classes.itemPrimaryText
          }
          primary={
            <div className={classes.titleContainer}>
              {data.title}&nbsp;
              {isShowSyncIcon && !federationData && (
                <span className={classes.titleIcon}>
                  <CustomIcon id="icon-refresh" style={{ fontSize: 9 }} />
                </span>
              )}
              {federationData && (
                <span
                  className={classes.itemFederationTextServerName}
                >{`(${data.federationServerName})`}</span>
              )}
            </div>
          }
        />
        <ListItemSecondaryAction className={classes.itemActionButtonsContainer}>
          <CustomTooltip title={Language.translate("Locate")} placement="top">
            <IconButton
              className="zoomToCoord"
              edge="end"
              aria-label="locate"
              onClick={() => zoomToCoord()}
            >
              <MyLocationIcon />
            </IconButton>
          </CustomTooltip>
          {mwState.verifyEditPermission && !federationData && (
            <CustomTooltip
              title={Language.translate("Edit")}
              placement="bottom"
            >
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => {
                  handleEdit(data);
                  const feature = data.id
                    ? GeofenceOverlay.getFeature(data.id)
                    : null;
                  GeofenceOverlay.setDrawFeature(feature);
                }}
              >
                <EditIcon />
              </IconButton>
            </CustomTooltip>
          )}
          {mwState.verifyDeletePermission && !federationData && (
            <CustomTooltip
              title={Language.translate("Delete")}
              placement="bottom"
            >
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() =>
                  handleItemDelete(
                    `${mwState.routeUrl}/${data.id}`,
                    "geofence",
                    "Geofence",
                    data.title
                  )
                }
              >
                <DeleteIcon />
              </IconButton>
            </CustomTooltip>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <GeofenceDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}
