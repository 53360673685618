import React, { useState, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import {
  makeStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Internal
import Device from "sccDevice";
import Language from "sccLanguage";
import Utils from "sccUtils";
import UserSetting from "sccUserSetting";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import { moduleListingDetails } from "@Styles/ModuleListingDetails";
import Group from "sccGroup";
import GeofenceSetting from "@Modules/geofence/scripts/GeofenceSetting";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
  noDevice: {
    textAlign: "center",
  },
}));

export default function GeofenceDetail({ data }) {
  const [value, setValue] = useState(0);
  const [devices, setDevices] = useState([]);
  const isColourEnabled =
    GeofenceSetting.get("custom_colour_enabled") || data.custom_colour_on;
  const federationData = data.federationServerId;
  const colorLabel = data.label || data.custom_colour_details?.label;
  const colorHex = data.hex || data.custom_colour_details?.hex;
  useEffect(() => {
    const devicesFromGroups = Group.getUniqueDeviceList({
      groups: data.triggers?.groups || [],
      devices: data.triggers?.devices || [],
    });

    setDevices(Device.getAvaiableDevices(_.uniq(devicesFromGroups)));
  }, []);

  function handleTabs(e, val) {
    setValue(val);
  }

  function TabPanel(props) {
    const { children, value, index } = props;
    return (
      <div>
        {value === index && <React.Fragment>{children}</React.Fragment>}
      </div>
    );
  }

  const classes = useStyles();
  //style for the active geofence colour
  const style = {
    geofenceColour: {
      background: colorHex,
      position: "flex",
      width: "100%",
      height: "20px",
    },
  };

  return (
    <Grid item xs={12} className={classes.detailsContainer}>
      <Tabs
        value={value}
        onChange={handleTabs}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          label={Language.translate("Info")}
          icon={<CustomIcon fontSize="small" id="icon-info-circle" />}
        />
        {!federationData && (
          <Tab
            label={Language.translate("Triggers")}
            icon={
              <Badge badgeContent={devices.length || 0} color="primary">
                <CustomIcon fontSize="small" id="icon-group" />
              </Badge>
            }
          />
        )}
        {!federationData && (
          <Tab
            label={Language.translate("Tactical")}
            icon={
              <Badge
                badgeContent={
                  Device.getAvaiableDevices(data.sync?.devices).length || 0
                }
                color="primary"
              >
                <CustomIcon fontSize="small" id="icon-refresh" />
              </Badge>
            }
          />
        )}
      </Tabs>
      <TabPanel value={value} index={0}>
        <Grid container>
          <Grid item xs={6}>
            <List>
              <ListItem>
                <ListItemText primary={Language.translate("Information")} />
              </ListItem>
              <ListItem>
                <ListItemText
                  className="itemTextFirst"
                  primary={Language.translate("Annotation").toUpperCase() + ":"}
                />
                <ListItemText className="itemTextSecond" primary={data.note} />
              </ListItem>
              <ListItem>
                <ListItemText
                  className="itemTextFirst"
                  primary={Language.translate("Status").toUpperCase() + ":"}
                />
                <ListItemText
                  className="itemTextSecond"
                  primary={
                    data.active
                      ? Language.translate("Active")
                      : Language.translate("Inactive")
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  className="itemTextFirst"
                  primary={Language.translate("Shape").toUpperCase() + ":"}
                />
                <ListItemText className="itemTextSecond" primary={data.shape} />
              </ListItem>
              {(data.min_speed || data.max_speed) && (
                <>
                  {UserSetting.get("speed_format") && (
                    <ListItem>
                      <ListItemText
                        className="itemTextFirst"
                        primary={Language.translate("SPEED UNIT") + ":"}
                      />
                      <ListItemText
                        className="itemTextSecond"
                        primary={UserSetting.get("speed_format")}
                      />
                    </ListItem>
                  )}
                  {data.min_speed && (
                    <ListItem>
                      <ListItemText
                        className="itemTextFirst"
                        primary={
                          Language.translate("Minimum Speed").toUpperCase() +
                          ":"
                        }
                      />
                      <ListItemText
                        className="itemTextSecond"
                        primary={Utils.transformSpeedFromKph(
                          data.min_speed,
                          UserSetting.get("speed_format")
                        )}
                      />
                    </ListItem>
                  )}
                  {data.max_speed && (
                    <ListItem>
                      <ListItemText
                        className="itemTextFirst"
                        primary={
                          Language.translate("Maximum Speed").toUpperCase() +
                          ":"
                        }
                      />
                      <ListItemText
                        className="itemTextSecond"
                        primary={Utils.transformSpeedFromKph(
                          data.max_speed,
                          UserSetting.get("speed_format")
                        )}
                      />
                    </ListItem>
                  )}
                </>
              )}
              {data.shape === "circle" && (
                <ListItem>
                  <ListItemText
                    className="itemTextFirst"
                    primary={Language.translate("Radius").toUpperCase() + ":"}
                  />
                  <ListItemText
                    className="itemTextSecond splitIn2"
                    primary={
                      <CustomTooltip
                        title={data.width || "N/A"}
                        placement="top"
                      >
                        {data.width || "N/A"}
                      </CustomTooltip>
                    }
                  />
                  <ListItemText
                    className="itemTextFirst splitIn2"
                    primary={Language.translate("Meters").toUpperCase()}
                  />
                </ListItem>
              )}
              {data.shape === "path" && (
                <ListItem>
                  <ListItemText
                    className="itemTextFirst"
                    primary={Language.translate("Radius").toUpperCase() + ":"}
                  />
                  <ListItemText
                    className="itemTextSecond splitIn2"
                    primary={data.width || "N/A"}
                  />
                  <ListItemText
                    className="itemTextFirst splitIn2"
                    primary={Language.translate("Meters").toUpperCase()}
                  />
                </ListItem>
              )}
              <ListItem>
                <ListItemText
                  className="itemTextFirst"
                  primary={Language.translate("Type").toUpperCase() + ":"}
                />
                <ListItemText
                  className="itemTextSecond"
                  primary={
                    data.inclusive
                      ? Language.translate("Inclusive")
                      : Language.translate("Exclusive")
                  }
                />
              </ListItem>
              {colorLabel && isColourEnabled && (
                <ListItem>
                  <ListItemText
                    className="itemTextFirst"
                    primary={
                      Language.translate("colour Label").toUpperCase() + ":"
                    }
                  />
                  <ListItemText
                    className="itemTextSecond"
                    primary={colorLabel}
                  />
                </ListItem>
              )}
              {colorHex && isColourEnabled && (
                <ListItem>
                  <ListItemText
                    className="itemTextFirst"
                    primary={Language.translate("Colour").toUpperCase() + ":"}
                  />
                  <ListItemText className="itemTextSecond">
                    <div style={style.geofenceColour}></div>
                  </ListItemText>
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={6}>
            <List>
              <ListItem>
                <ListItemText primary={Language.translate("Coordinates")} />
              </ListItem>
              <ListItem>
                <ListItemText
                  xs={12}
                  primary={
                    data.coordinates?.length > 0 &&
                    data.coordinates.map((coord, index) => {
                      return (
                        <Grid
                          xs={12}
                          key={index}
                          item
                          className="itemTextAlone"
                        >
                          {coord.longitude}, {coord.latitude}
                        </Grid>
                      );
                    })
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List>
          {devices.length > 0 ? (
            devices.map((deviceId) => {
              const deviceInfo = Device.get(deviceId);
              if (deviceInfo) {
                return (
                  <ListItem key={deviceId}>
                    <ListItemText
                      primary={deviceInfo.name}
                      className="itemTextAlone"
                    />
                  </ListItem>
                );
              }
            })
          ) : (
            <ListItem className={classes.noDevice}>
              <ListItemText className="itemTextAlone">
                {Language.translate("There are no devices in Triggers")}
              </ListItemText>
            </ListItem>
          )}
        </List>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <List>
          {data.sync.devices?.length > 0 ? (
            data.sync.devices.map((deviceId) => {
              const deviceInfo = Device.get(deviceId);
              if (deviceInfo) {
                return (
                  <ListItem key={deviceId}>
                    <ListItemText
                      primary={deviceInfo.name}
                      className="itemTextAlone"
                    />
                  </ListItem>
                );
              }
            })
          ) : (
            <ListItem className={classes.noResults}>
              <ListItemText className="itemTextAlone">
                {Language.translate("There are no devices in Tactical")}
              </ListItemText>
            </ListItem>
          )}
        </List>
      </TabPanel>
    </Grid>
  );
}
