import Cookies from "universal-cookie";
import moment from "moment";
import "moment/dist/locale/fr";
import "moment/dist/locale/es";

var _ = require("lodash");
var bootbox = require("bootbox");

const log = require("loglevel");
log.setLevel(log.levels.DEBUG);

let positionId = null;
let currentPosition = null;

const cookies = new Cookies();

/**
 * Common utility class
 *
 * @class Utils
 */
class Utils {
  static get position() {
    return currentPosition;
  }

  static get apiUrlPrefix() {
    return process.env.REACT_APP_PLATFORM_PREFIX || "/api/v1";
  }

  static getPosition() {
    return new Promise(function (resolve) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          currentPosition = position;
          resolve(position);
        });
      } else {
        log.warn("Geolocation is not supported by this browser.");
      }
    });
  }

  static watchPosition() {
    function success(pos) {
      log.debug("GeoLocation: position changed");
      currentPosition = pos;
    }

    function error(err) {
      log.warn("GeoLocation Watch:", err);
    }

    const options = {
      enableHighAccuracy: true,
      timeout: 30000,
      maximumAge: 0,
    };
    positionId = navigator.geolocation.watchPosition(success, error, options);

    return positionId;
  }

  static clearWatch() {
    currentPosition = null;
    navigator.geolocation.clearWatch(positionId);
  }
}

Utils.httpRequestHandler = function (options) {
  const token = Utils.getCookie("accesstoken");
  let header = {};

  if (process.env.REACT_APP_IS_INDEPENDENT_DEV) {
    if (token) {
      header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      };
    } else {
      header = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      };
    }

    return fetch(options.url, {
      method: options.method,
      credentials: "include",
      headers: header,
      body: JSON.stringify(options.body),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        //left for debugging use
        // var message = data.message ? "(" + data.message + ")" : "";
        // const end = new Date();
        // const total = end - start;
        // log.debug(response.config.method, response.config.url, response.status, message, "Time", total);
        return { data };
        // return Promise.resolve();
      })
      .catch((e) => log.info(e));
  } else {
    return fetch(options.url, {
      method: options.method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(options.body),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        //left for debugging use
        // var message = data.message ? "(" + data.message + ")" : "";
        // const end = new Date();
        // const total = end - start;
        // log.debug(response.config.method, response.config.url, response.status, message, "Time", total);
        return { data };
        // return Promise.resolve();
      })
      .catch((e) => log.info(e));
  }
};

/**
 * Gets a cookie by name
 *
 * @method Utils.getCookie
 * @static
 * @param {String} cname - cookie name
 */
Utils.getCookie = function (cname) {
  return cookies.get(cname);
};

/**
 * Sets a cookie by name
 *
 * @method Utils.setCookie
 * @static
 * @param {String} cname - cookie name, value, options
 */
Utils.setCookie = function (cname, value, options) {
  cookies.set(cname, value, options);
  return;
};

Utils.removeCookie = function (cname) {
  cookies.remove(cname);
  return;
};

Utils.notify = function (
  title,
  message,
  subMessage,
  variant,
  acknowledgeAlertId = ""
) {
  var btn = document.getElementById("snackbarHelper");
  if (btn) {
    btn.setAttribute("data-title", title);
    btn.setAttribute("data-message", message);
    btn.setAttribute("data-submessage", subMessage);
    btn.setAttribute("data-variant", variant);
    btn.setAttribute("data-acknowledgealertid", acknowledgeAlertId);
    btn.click();
  }
};

Utils.poiMenuNotify = function (title, message, subMessage, variant, poiId) {
  var btn = document.getElementById("poiMenuSnackbarHelper");
  btn.setAttribute("data-title", title);
  btn.setAttribute("data-message", message);
  btn.setAttribute("data-submessage", subMessage);
  btn.setAttribute("data-variant", variant);
  btn.setAttribute("data-poi", poiId);
  btn.click();
};

Utils.geofenceMenuNotify = function (
  title,
  message,
  subMessage,
  variant,
  geofenceId
) {
  var btn = document.getElementById("geofenceMenuSnackbarHelper");
  btn.setAttribute("data-title", title);
  btn.setAttribute("data-message", message);
  btn.setAttribute("data-submessage", subMessage);
  btn.setAttribute("data-variant", variant);
  btn.setAttribute("data-geofence", geofenceId);
  btn.click();
};

Utils.notifyOld = function (arg1, arg2) {
  var Language = require("sccLanguage").default;
  const errorDelay = 10000;
  var options;

  if (_.isObject(arg1)) {
    options = arg1;
  } else {
    options = arg2 || {};
    options.message = options.message || arg1;
  }

  log.trace();
  options.templateUrl = options.templateUrl || "notify_threat_template.html";

  if (options.type === "error") {
    options.delay = errorDelay;
  }
  if (options.title === "Error") options.title = Language.translate("Error");
  // Translates messages coming back from the Back-end
  if (options.message) {
    if (options.message === '"start_timestamp" invalid timestamp')
      options.message = Language.translate("Please input a valid Start Date");
    else if (options.message === '"end_timestamp" invalid timestamp')
      options.message = Language.translate("Please input a valid End Date");
    else options.message = Language.translate(options.message);
  }

  return Utils.Notify(options).then(function (childScope) {
    childScope.header = options.header;
    childScope.icon = options.icon;
    childScope.data = options.data;

    options.data.kill = childScope.kill;

    childScope.leftButton = options.leftButton;
    childScope.rightButton = options.rightButton;

    childScope.closeButtonClick = function () {
      options.onCloseEvent();
      childScope.kill(true);
    };

    return Promise.resolve(childScope);
  });
};

/* returns value from SECONDS to HOURS  */
Utils.transformSecondsToHours = function (value, time) {
  if (value == null) return null;
  var unit;
  switch (time) {
    case "minutes":
      //converting seconds into minutes
      unit = 60;
      break;
    default:
      //converting seconds into Hours
      unit = 3600;
  }
  return Math.round((value / unit + 0.00001) * 100) / 100;
};

Utils.roundHours = function (hours) {
  return Math.round(hours * 100) / 100;
};

Utils.convertHoursToSeconds = function (hours) {
  return Math.round(hours * 3600 * 100) / 100;
};

Utils.convertHoursToMinutes = function (hours) {
  return Math.round(hours * 60 * 100) / 100;
};

/* returns value from HOURS to SECONDS */
Utils.transformHoursToSeconds = function (value, time) {
  if (value == null) return null;

  var unit;
  switch (time) {
    case "minutes":
      //converting seconds into minutes
      unit = 0.0166667;
      break;
    default:
      //converting seconds into Hours
      unit = 3600;
  }
  return Math.round((value * unit + 0.00001) * 100) / 100;
};

/* returns value from METRES to KILOMETRES  */
Utils.transformMetresToKm = function (value, speedUnit) {
  if (value == null) return null;
  var unit;
  switch (speedUnit) {
    case "Kilometres":
      unit = 1000;
      break;
    case "Feet":
      unit = 0.3048;
      break;
    default:
      unit = 1000;
  }
  return Math.round((value / unit + 0.00001) * 100) / 100;
};

/* returns value from KILOMETRES to METRES */
Utils.transformKmToMetres = function (value) {
  if (value == null) return null;
  var metres = Math.round((value * 1000 + 0.00001) * 100) / 100;

  return metres;
};

Utils.transformSpeedFromKph = function (value, speedFormat) {
  var UserSetting = require("sccUserSetting").default;
  if (value == null) return null;
  speedFormat = speedFormat || UserSetting.get("speed_format");
  var unit;
  switch (speedFormat) {
    case "MPH":
      //converting speed value in MPH to KPH
      unit = 0.621371;
      break;
    case "KNOTS":
      //converting speed value in MPH to KNOTS
      unit = 0.539957;
      break;
    default:
      unit = 1;
  }
  return Math.round((value * unit + 0.00001) * 100) / 100;
};

Utils.transformSpeedToKph = function (value, speedFormat) {
  var UserSetting = require("sccUserSetting").default;
  if (value == null || value === "") return null;
  speedFormat = speedFormat || UserSetting.get("speed_format");
  var unit;
  switch (speedFormat) {
    case "MPH":
      //converting speed value from KPH to MPH
      unit = 1.609344;
      break;
    case "KNOTS":
      //converting speed value from KPH to KNOTS
      unit = 1.852;
      break;
    default:
      unit = 1;
  }
  return Math.round((value * unit + 0.00001) * 100) / 100;
};

/**
 * show an alert window that use needs to press Ok for it to dissappear
 *
 * @param {String} message message to be shown to the user
 */
Utils.alert = function (message, callback) {
  bootbox.alert(message, callback);
};

/**
 * shows a confrimation window to the user to decide whether or not to continue
 *
 * @param {String} message message to be shown on the confirmation window
 * @param {Function} callback callback function that runs if conditions are met
 * @praram {Boolean} condition showes whether or not the confirmation should be shown.
 */
Utils.confirm = function (message, callback, condition) {
  var Language = require("sccLanguage").default;
  return new Promise(function (resolve) {
    function confirmPromise() {
      bootbox.confirm({
        message,
        buttons: {
          confirm: {
            className: "btnConfirmBoxOk btn-primary",
            label: "<i class='fa fa-check'></i> " + Language.translate("OK"),
          },
          cancel: {
            className: "btnConfirmBoxCancel",
            label:
              "<i class='fa fa-times'></i> " + Language.translate("Cancel"),
          },
        },
        callback: function (confirmed) {
          callback(confirmed);
          if (confirmed) {
            resolve(true);
          } else {
            resolve(false);
          }
        },
      });
    }

    if (condition == null) {
      confirmPromise();
    } else if (condition) {
      confirmPromise();
    } else {
      callback(true);
      resolve();
    }
  });
};

/**
 * provides a lodash like function to remove key from object for javascript
 * Original lodash function not used as it is supposed to be deprecated soon
 * the first argument is the object
 * second argument is the array of keys to be removed
 */
Utils.omit = function (originalObject = {}, keysToOmit = []) {
  const clonedObject = { ...originalObject };
  for (const path of keysToOmit) {
    _.unset(clonedObject, path);
  }
  return clonedObject;
};

/**
 * provides a C# like formatting for javascript
 *
 * the first argument is the string with placeholders like {0}
 * other arguments are replacements for plachodlers
 */
Utils.formatString = function () {
  // The string containing the format items (e.g. "{0}")
  // will and always has to be the first argument.
  var theString = arguments[0];

  // start with the second argument (i = 1)
  for (var i = 1; i < arguments.length; i++) {
    // "gm" = RegEx options for Global search (more than one instance)
    // and for Multiline search
    var regEx = new RegExp("\\{" + (i - 1) + "\\}", "gm");
    theString = theString.replace(regEx, arguments[i]);
  }

  return theString;
};

/**
 * Builds the csv data based on options passed to the function
 * and calls the GuiUtils class to download and save the file on the client machine
 *
 * @param {Array} rawData - array of JSON obj containing the data set
 * @param {Object} options - contains certain parameters that use can use to customize file name and get custom headers
 *
 * @param {Object} options.custom_headers - Key-Value pair of header values (keys) and corressponding custom headers (values)
 *
 * @param {String} options.file_title - custom title for the file being downloaded
 */
Utils.generateCSVFileAndDownload = function (rawData, options) {
  //Error handling: Data array has no rows of data
  if (rawData.length === 0) {
    throw new Error(
      "There is no data to be exported to CSV. Length of 1st parameter is 0"
    );
  }

  let csvData = {
    data: "",
    headers: [],
    data_keys: [],
  };

  //if custom headers are not passed with options set parameters/keys of the data as default headers
  if (!options.custom_headers) {
    csvData.headers = _.keys(rawData[0]);
    csvData.data_keys = csvData.headers;
  } else if (options.custom_headers) {
    csvData.data_keys = _.keys(rawData[0]);
    _.each(csvData.data_keys, function (key) {
      if (options.custom_headers[key] !== undefined) {
        csvData.headers.push(options.custom_headers[key]);
      }
    });
  }

  buildCSVHeader(csvData);
  buildCSVData(csvData, rawData);

  var GuiUtils = require("sccGuiUtils").default;
  GuiUtils.saveTextAsFile(
    csvData.data,
    options.file_title,
    "text/csv;charset=utf-8"
  );
  return Promise.resolve();
};

//Builds and appends the headers row for the csv data set
function buildCSVHeader(csvData) {
  for (var i = 0; i < csvData.headers.length; i++) {
    if (i === csvData.headers.length - 1) {
      csvData.data += csvData.headers[i] + "\n";
    } else {
      csvData.data += csvData.headers[i] + ",";
    }
  }
  return;
}

//Builds and appends data rows to the csv data set
function buildCSVData(csvData, rawData) {
  _.each(rawData, function (data) {
    for (var i = 0; i < csvData.headers.length; i++) {
      var key = csvData.data_keys[i];

      let dataValue = "";

      if (data[key] !== undefined) {
        dataValue = data[key];
      }

      //Remove any commas in the data set
      if (dataValue != null) {
        dataValue = dataValue.toString();

        if (dataValue.indexOf(",") > -1) {
          var msg = "Export to CSV: Removing ',' from " + dataValue;
          log.warn(msg);
        }
        dataValue = dataValue.replace(",", " ");
      }

      if (i === csvData.headers.length - 1) {
        csvData.data += dataValue + "\n";
      } else {
        csvData.data += dataValue + ",";
      }
    }
  });
  return;
}

Utils.MinDec2DegDec = function (coord) {
  var start = 0;
  var end =
    coord.indexOf("°", start) !== -1
      ? coord.indexOf("°", start)
      : coord.indexOf("d", start) !== -1
      ? coord.indexOf("d", start)
      : -1;
  var deg = coord.substring(start, end);
  deg = deg - 0;

  start = end + 1;
  end = coord.length - 1;
  var frac = coord.substring(start, end);

  frac = Number((frac / 60.0).toFixed(5));

  start = end;
  end = start + 1;
  var direction = coord.substring(start, end);
  var sign = direction == "W" || direction == "S" ? -1 : 1;

  return (deg + frac) * sign;
};

Utils.DMS2DegDec = function (dmsStr) {
  var start = 0;
  var end =
    dmsStr.indexOf("°", start) !== -1
      ? dmsStr.indexOf("°", start)
      : dmsStr.indexOf("d", start) !== -1
      ? dmsStr.indexOf("d", start)
      : -1;
  var degree = Number(dmsStr.substring(start, end));

  start = end + 1;
  end =
    dmsStr.indexOf("′", start) !== -1
      ? dmsStr.indexOf("′", start)
      : dmsStr.indexOf("'", start) !== -1
      ? dmsStr.indexOf("'", start)
      : -1;
  var minute = Number(dmsStr.substring(start, end));

  start = end + 1;
  end =
    dmsStr.indexOf("″", start) !== -1
      ? dmsStr.indexOf("″", start)
      : dmsStr.indexOf(`"`, start) !== -1
      ? dmsStr.indexOf(`"`, start)
      : -1;
  var second = Number(dmsStr.substring(start, end));

  start = end + 1;
  end = start + 1;
  //var sign = (direction == "W" || direction == "S") ? -1 : 1;

  //var sign = (dmsStr.indexOf("W") > -1 || dmsStr.indexOf("S") > -1) ? -1 : 1;

  var sign = 1;
  if (dmsStr.indexOf("W") > -1 || dmsStr.indexOf("S") > -1) {
    sign = -1;
  }

  var result = ((second / 60 + minute) / 60 + degree) * sign;

  return result;
};

/**
 * extending String class to add function to manipulated string based on provided index
 */
String.prototype.replaceAt = function (index, character) {
  return (
    this.substr(0, index) + character + this.substr(index + character.length)
  );
};

function hexToBuffer(hex) {
  const buffer = new Buffer.from(hex, "hex");

  return buffer;
}

function arrayBufferToString(ab) {
  const decoder = new TextDecoder();
  const str = decoder.decode(ab);

  return str;
}

function str2ab(str) {
  var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  var bufView = new Uint16Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

async function importSecretKey(str) {
  const key = await window.crypto.subtle.importKey(
    "raw",
    hexToBuffer(str),
    "AES-CBC",
    true,
    ["encrypt", "decrypt"]
  );

  return key;
}

function buf2hex(buffer) {
  // buffer is an ArrayBuffer
  return [...new Uint8Array(buffer)]
    .map((x) => x.toString(16).padStart(2, "0"))
    .join("");
}

const defaultIv = new Uint8Array([
  15, 23, 52, 49, 26, 58, 45, 47, 85, 65, 45, 12, 65, 85, 47, 65,
]);
const defaultKey =
  "5BDE3C52FE7C5E701F54DDF5AE120A5F390174DED4A48763071C5CFDDF4367BB";

Utils.encryptMsg = async function (toEncrypted) {
  try {
    const iv = defaultIv;
    const generatedKey = await importSecretKey(defaultKey);
    const encryptedMsg = await window.crypto.subtle.encrypt(
      {
        name: "AES-CBC",
        iv: iv,
      },
      generatedKey,
      new Buffer.from(str2ab(toEncrypted))
    );

    return buf2hex(encryptedMsg);
  } catch (err) {
    console.log("err", err);
    return {};
  }
};

Utils.decryptMsg = async function (toDecrypt) {
  try {
    const generatedKey = await importSecretKey(defaultKey);

    const buffer = hexToBuffer(toDecrypt);

    const decryptedMsg = await window.crypto.subtle.decrypt(
      { name: "AES-CBC", iv: defaultIv },
      generatedKey,
      buffer
    );

    return arrayBufferToString(decryptedMsg).replace(/\0/g, "");
  } catch (err) {
    console.log("decrypt err", err);
  }
};

Utils.getFedKeyNameForModule = function (moduleName) {
  switch (moduleName) {
    case "Poi":
      return "pois";
    case "Geofence":
      return "geofences";
    default:
      break;
  }
};

Utils.arrayToObject = function (arr, key) {
  return (
    arr && Object.assign({}, ...arr.map((item) => ({ [item[key]]: item })))
  );
};

Utils.federationConnectionStatus = async () => {
  var options = {
    url: Utils.apiUrlPrefix + "/tak/checkLoginStatus",
    method: "GET",
  };
  return Utils.httpRequestHandler(options)
    .then((response) => {
      if (response?.data?.result) {
        return response.data.result.result;
      }
    })
    .catch((e) => log.info(e));
};

Utils.disconnectFederationServer = async (id, name) => {
  const options = {
    url: "api/v1/tak/disconnect",
    method: "POST",
    body: {
      id: id,
      federationServerId: id,
      federationServerName: name,
    },
  };
  return await Utils.httpRequestHandler(options);
};

export default Utils;
