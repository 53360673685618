import React, { useContext } from "react";

// Material-UI
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SensorsIcon from "@material-ui/icons/NetworkCheck";

// Components
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { FederationDataContext } from "@Modules/federation_data/context/FederationDataContext";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import Language from "sccLanguage";
import Utils from "sccUtils";
import FedDetail from "./FedDetail";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  connectedChip: {
    marginTop: 10,
    padding: "4px 8px",
    backgroundColor: theme.palette.colors.green.main,
    borderColor: theme.palette.colors.green.main,
    float: "left",
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    color: theme.palette.colors.white.main,
  },
  connectedIcon: {
    color: theme.palette.colors.white.main,
    width: 12,
    height: 12,
    marginRight: 4,
  },
}));

export default function TakList(props) {
  const [mwState] = useContext(ModuleWrapperContext);
  const [fedState, setFedState] = useContext(FederationDataContext);
  const data = props.data;
  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;
  const handleFederationConnection = props.handleFederationConnection;

  function handleDelete() {
    handleItemDelete(
      `${mwState.routeUrl}/${data.id}`,
      mwState.title,
      "Federation",
      data.name,
      data.id
    );
    Utils.disconnectFederationServer(data.id, data.name).then((res) => {
      setFedState((p) => ({
        ...p,
        federationConnection: {},
      }));
    });
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={classes.listItem}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <ListItemIcon>
          <CustomTooltip title={data.name} placement="left">
            <span>
              <CustomIcon id={`icon-${mwState.icon}`} fontSize="small" />
            </span>
          </CustomTooltip>
        </ListItemIcon>

        <ListItemText id={data.id} primary={`${data.name}`} />

        <ListItemSecondaryAction className={classes.itemActionButtonsContainer}>
          {data.role_id !== 2 ? (
            <React.Fragment>
              {fedState.federationConnection?.federationServerId ===
                data.id && (
                <div className={classes.connectedChip}>
                  <SensorsIcon className={classes.connectedIcon} />
                  {Language.translate("Connected")}
                </div>
              )}
              {mwState.verifyEditPermission && (
                <CustomTooltip
                  title={Language.translate("Connect")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="test-connection"
                    color={
                      fedState.federationConnection?.federationServerId ===
                      data.id
                        ? "primary"
                        : undefined
                    }
                    onClick={() => handleFederationConnection(data)}
                  >
                    <SensorsIcon />
                  </IconButton>
                </CustomTooltip>
              )}
              {mwState.verifyEditPermission && (
                <CustomTooltip
                  title={Language.translate("Edit")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEdit(data)}
                  >
                    <EditIcon />
                  </IconButton>
                </CustomTooltip>
              )}
              {mwState.verifyDeletePermission && (
                <CustomTooltip
                  title={Language.translate("Delete")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={handleDelete}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CustomTooltip>
              )}
            </React.Fragment>
          ) : null}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <FedDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}
