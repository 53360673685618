import React, { useContext, useState, useEffect } from "react";

// Material-UI
import { Grid, makeStyles, alpha } from "@material-ui/core";
import Battery20Icon from "@material-ui/icons/Battery20";
import BatteryCharging80Icon from "@material-ui/icons/BatteryCharging80";

// Components
import CustomTooltip from "@Components/CustomTooltip";

// Internal
import { MinimizeContext } from "../../context/MinimizeContext";
import { UserSettingContext } from "../../../../user_setting/context/UserSettingContext";
import Clock from "sccClock";
import Device from "sccDevice";
import GuiUtils from "sccGuiUtils";
import Language from "sccLanguage";
import OlMap from "sccOlMapNew";
import TimeUtils from "sccTimeUtils";
import HermesGateways from "sccHermesGateways";
import Utils from "sccUtils";
import WaveLastSASent from "./WaveLastSASent";
import Alert from "../../../../alert/scripts/Alert";

const useStyles = makeStyles((theme) => ({
  deviceListContainer: {
    display: "flex",
    flexDirection: "column",
  },

  deviceContentLayer: {
    height: 23,
    borderTop: `1px solid ${theme.palette.colors.gray.main}`,
  },

  deviceContentLeft: {
    fontSize: 12,
    padding: "2px 6px",
    textAlign: "left",
    backgroundColor: theme.palette.colors.gray.main,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  deviceContentLeftRedBattery: {
    fontSize: 12,
    padding: "2px 6px",
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
    backgroundColor: theme.palette.textbox.error.main,
    color: theme.palette.colors.white.main,
    display: "flex",
    alignItems: "center",
  },

  deviceContentLeftYellowBattery: {
    fontSize: 12,
    padding: "2px 6px",
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
    backgroundColor: theme.palette.colors.yellow.pure,
    display: "flex",
    alignItems: "center",
  },

  deviceContentRight: {
    fontSize: 13,
    padding: "2px 6px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "right",
  },

  deviceContentRightRedBattery: {
    fontSize: 13,
    padding: "2px 6px",
    textAlign: "right",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
    backgroundColor: theme.palette.textbox.error.main,
    color: theme.palette.colors.white.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  deviceContentRightYellowBattery: {
    fontSize: 13,
    padding: "2px 6px",
    textAlign: "right",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
    backgroundColor: theme.palette.colors.yellow.pure,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  darkBg: {
    backgroundColor: theme.palette.colors.gray.dark,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: `2px 2px 15px -1px ${theme.palette.colors.gray.main}`,
    border: `1px solid ${theme.palette.colors.gray.main}`,
  },

  speedFormat: {
    backgroundColor: alpha(theme.palette.colors.black.main, 0.5),
    fontSize: 11,
    fontWeight: "bold",
    color: theme.palette.colors.white.main,
    minWidth: 10,
    padding: "3px 7px",
    marginLeft: 10,
    borderRadius: 10,

    "& span": {
      fontSize: 11,
    },
  },

  alignCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  deviceBattery: {
    color: theme.palette.colors.white.main,
    fontSize: 16,
  },
  deviceBatteryDark: {
    color: theme.palette.colors.black.main,
    fontSize: 16,
  },
  locationOverflow: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default function DeviceInfo({ device, displayMode }) {
  if (displayMode === "popup") {
    var { state } = useContext(MinimizeContext);
  }

  const { userSettingData } = useContext(UserSettingContext);
  const classes = useStyles();
  const deviceTypeFeatures = Device.getDeviceType(device.type_id);

  const [reportAge, setReportAge] = useState(
    TimeUtils.getAge(device?.report_timestamp) || "N/A"
  );

  const isFederationData = Boolean(device.federationServerId);
  const federationServerName = device.federationServerName;
  const REACT_APP_FEDERATION_ASSET_TYPE_DEFAULT =
    process.env.REACT_APP_FEDERATION_ASSET_TYPE_DEFAULT;

  // Return Device mode translated
  const getAssetMode = (mode) => {
    if (mode === "Standalone" || mode === "Open Protocol") {
      return Language.translate(mode);
    } else {
      return mode;
    }
  };

  const location =
    (device &&
      device.latitude &&
      OlMap.formatCoordinate([device.longitude, device.latitude])) ||
    "N/A";

  const deviceSpeed = () => {
    if (device.speed === null) return "N/A";
    switch (userSettingData["speed_format"]) {
      case "MPH":
        return Utils.transformSpeedFromKph(device.speed, "MPH");
      case "KNOTS":
        return Utils.transformSpeedFromKph(device.speed, "KNOTS");
      default:
        return device.speed;
    }
  };

  // update report age state
  const calcAge = (rt) => {
    setReportAge(TimeUtils.getAge(rt));
  };

  const getNonReportThreshold = () => {
    switch (device.non_report_threshold) {
      case 900000:
        return Language.translate("15 Minutes");
      case 1800000:
        return Language.translate("30 Minutes");
      case 3600000:
        return Language.translate("60 Minutes");
      case 7200000:
        return Language.translate("2 Hours");
      case 14400000:
        return Language.translate("4 Hours");
      case 28800000:
        return Language.translate("8 Hours");
      case 57600000:
        return Language.translate("12 Hours");
      case 115200000:
        return Language.translate("24 Hours");
      case 230400000:
        return Language.translate("48 Hours");
      case 460800000:
        return Language.translate("72 Hours");
      default:
        return Language.translate("N/A");
    }
  };

  useEffect(() => {
    //  update report age every one second
    const interval = setInterval(() => calcAge(device?.report_timestamp), 1000);

    calcAge(device?.report_timestamp);

    return () => {
      clearInterval(interval);
    };
  }, [device]);

  // get parent gatewayDevice names
  const getParentGatewayDeviceNames = (deviceArr) => {
    let returnString = "";

    if (deviceArr?.length > 0) {
      _.each(deviceArr, (deviceId) => {
        const name = Device.get(deviceId).name;
        returnString += `${name}, `;
      });
    }

    if (returnString === "") {
      return null;
    } else {
      return returnString.substring(0, returnString.length - 2);
    }
  };

  // define common list fields
  const commonRenderedList = () => {
    if (deviceTypeFeatures) {
      return (
        <>
          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("TYPE")}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {deviceTypeFeatures?.title === "Wave"
                ? "NORTAC Wave"
                : deviceTypeFeatures?.title}
              {deviceTypeFeatures?.title ===
                REACT_APP_FEDERATION_ASSET_TYPE_DEFAULT &&
                isFederationData && (
                  <CustomTooltip
                    title={device?.typeOriginal}
                    arrow
                    placement="top"
                  >
                    &nbsp;({device?.typeOriginal})
                  </CustomTooltip>
                )}
            </Grid>
          </Grid>

          {isFederationData && (
            <Grid container className={classes.deviceContentLayer}>
              <Grid item xs={5} className={classes.deviceContentLeft}>
                {Language.translate("Created By").toUpperCase()}
              </Grid>
              <Grid item xs={7} className={classes.deviceContentRight}>
                {federationServerName}
              </Grid>
            </Grid>
          )}

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("Mode").toUpperCase()}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {getAssetMode(deviceTypeFeatures.modes[device.mode]?.title) ||
                "N/A"}
            </Grid>
          </Grid>
          {[...Device.getHermesDevices(), "NORTAC Orion"].includes(
            deviceTypeFeatures.title
          ) ? (
            <Grid container className={classes.deviceContentLayer}>
              <Grid item xs={5} className={classes.deviceContentLeft}>
                {deviceTypeFeatures.title == "NORTAC Orion"
                  ? `${Language.translate("IMEI")}`
                  : `${Language.translate("Serial Number").toUpperCase()}`}
              </Grid>
              <Grid item xs={7} className={classes.deviceContentRight}>
                {device.Serial_No || "N/A"}
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.deviceContentLayer}>
              <Grid item xs={5} className={classes.deviceContentLeft}>
                {Language.translate("IMEI")}
              </Grid>
              <Grid item xs={7} className={classes.deviceContentRight}>
                {device.imei || "N/A"}
              </Grid>
            </Grid>
          )}
          {deviceTypeFeatures.title === "NORTAC Orion" ? (
            <Grid container className={classes.deviceContentLayer}>
              <Grid item xs={5} className={classes.deviceContentLeft}>
                {`${Language.translate("Device ID")}`}
              </Grid>
              <Grid item xs={7} className={classes.deviceContentRight}>
                {device.imei || "N/A"}
              </Grid>
            </Grid>
          ) : null}

          {Device.getHermesDevices().includes(deviceTypeFeatures.title) ? (
            deviceTypeFeatures.title === "Trellisware TSM TW-950" ? (
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("IP Address").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.ip_address || "N/A"}
                </Grid>
              </Grid>
            ) : (
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("ALE Address").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.ale_address || "N/A"}
                </Grid>
              </Grid>
            )
          ) : null}

          {HermesGateways.getParentGatewayDevice(device.id)?.length > 0 && (
            <Grid container className={classes.deviceContentLayer}>
              <Grid item xs={5} className={classes.deviceContentLeft}>
                {Language.translate("Gateway").toUpperCase()}
              </Grid>
              <Grid item xs={7} className={classes.deviceContentRight}>
                {getParentGatewayDeviceNames(
                  HermesGateways.getParentGatewayDevice(device.id)
                ) || "N/A"}
              </Grid>
            </Grid>
          )}

          {displayMode === "moduleListing" && (
            <div>
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("COMM ID")}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.comm_id || "N/A"}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("NON REPORT ALERT")}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {getNonReportThreshold()}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("ENCRYPTION")}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.encryption_key || "N/A"}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("DECRYPTION")}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.encryption_key || "N/A"}
                </Grid>
              </Grid>
            </div>
          )}

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              <CustomTooltip
                title={OlMap.getUserLonlatFormat().toUpperCase()}
                arrow
                placement="top"
              >
                {OlMap.getUserLonlatFormat().toUpperCase()}
              </CustomTooltip>
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              <CustomTooltip title={location} arrow placement="top">
                <div className={classes.locationOverflow}>{location}</div>
              </CustomTooltip>
            </Grid>
          </Grid>

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("Altitude").toUpperCase()}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {device.altitude || "N/A"}
            </Grid>
          </Grid>

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("Course").toUpperCase()}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {GuiUtils.ConvertHeadingToString(device.heading) || "N/A"}
            </Grid>
          </Grid>

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("SPEED")}
              <span className={classes.speedFormat}>
                <CustomTooltip
                  title={userSettingData["speed_format"]}
                  arrow
                  placement="top"
                >
                  {userSettingData["speed_format"]}
                </CustomTooltip>
              </span>
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {deviceSpeed()}
            </Grid>
          </Grid>
        </>
      );
    }
  };

  const getLatestVehicleReportForDevice = (device) => {
    var vehicleAlerts = [];
    vehicleAlerts = Alert.getDeviceAlert(device?.id, "Vehicle")
      ? Object.values(Alert.getDeviceAlert(device?.id, "Vehicle"))
      : null;
    return vehicleAlerts?.length
      ? vehicleAlerts[vehicleAlerts.length - 1]
      : null;
  };

  const renderedListByDeviceType = () => {
    if (deviceTypeFeatures) {
      const deviceType = deviceTypeFeatures?.title?.toLowerCase() || "";
      const isLowerBattery =
        Device.get(device.id)?.battery_level != null
          ? Device.get(device.id).battery_level <= 10
          : false;
      const isChargingBattery = Device.get(device.id)?.battery_charge === true;
      const isRedBattery = isLowerBattery;

      switch (true) {
        case deviceType.includes("shadow"):
          return (
            <div className={classes.deviceListContainer}>
              {commonRenderedList()}
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Report Age").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {reportAge || "N/A"}
                </Grid>
              </Grid>

              {deviceTypeFeatures.modes[device.mode]?.title !== "Standalone" ? (
                <>
                  <Grid container className={classes.deviceContentLayer}>
                    <Grid item xs={5} className={classes.deviceContentLeft}>
                      {Language.translate("LAST GPS RPT")}
                    </Grid>
                    <Grid item xs={7} className={classes.deviceContentRight}>
                      {Clock.formatTimestamp(device.report_timestamp)}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.deviceContentLayer}>
                    <Grid item xs={5} className={classes.deviceContentLeft}>
                      {Language.translate("Tag Id").toUpperCase()}
                    </Grid>
                    <Grid item xs={7} className={classes.deviceContentRight}>
                      {device.assetId}
                    </Grid>
                  </Grid>
                  {device.consignment?.status?.door_open !== null && (
                    <Grid container className={classes.deviceContentLayer}>
                      <Grid item xs={5} className={classes.deviceContentLeft}>
                        {Language.translate("Door").toUpperCase()}
                      </Grid>
                      <Grid item xs={7} className={classes.deviceContentRight}>
                        {device.consignment?.status?.door_open
                          ? Language.translate("Open")
                          : device.consignment?.status?.door_open == null
                          ? "N/A"
                          : Language.translate("Closed")}
                      </Grid>
                    </Grid>
                  )}
                  {device.consignment?.status?.temperature !== null ? (
                    <Grid container className={classes.deviceContentLayer}>
                      <Grid item xs={5} className={classes.deviceContentLeft}>
                        {Language.translate("Temperature").toUpperCase()}
                      </Grid>
                      <Grid
                        item
                        container
                        xs={7}
                        className={classes.deviceContentRight}
                      >
                        <Grid item xs={4} className={classes.alignCenter}>
                          {device.consignment?.status?.temperature
                            ? device.consignment?.status?.temperature + "℃"
                            : ""}
                        </Grid>
                        <Grid item xs={4} className={classes.darkBg}>
                          <CustomTooltip
                            title={Language.translate("Lowest")}
                            arrow
                            placement="top"
                          >
                            <span>
                              {device.consignment?.settings?.temp_low !==
                                null &&
                              device.consignment?.settings?.temp_low >= -45 &&
                              device.consignment?.settings?.temp_low <= 45
                                ? device.consignment?.settings?.temp_low + "℃"
                                : "N/A"}
                            </span>
                          </CustomTooltip>
                        </Grid>
                        <Grid item xs={4} className={classes.darkBg}>
                          <CustomTooltip
                            title={Language.translate("Highest")}
                            arrow
                            placement="top"
                          >
                            <span>
                              {device?.consignment?.settings?.temp_high !==
                                null &&
                              device.consignment?.settings?.temp_high >= -45 &&
                              device.consignment?.settings?.temp_high <= 45
                                ? device.consignment?.settings?.temp_high + "℃"
                                : "N/A"}
                            </span>
                          </CustomTooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  {device.consignment?.status?.humidity !== null && (
                    <Grid container className={classes.deviceContentLayer}>
                      <Grid item xs={5} className={classes.deviceContentLeft}>
                        {Language.translate("Humidity").toUpperCase()}
                      </Grid>
                      <Grid
                        item
                        container
                        xs={7}
                        className={classes.deviceContentRight}
                      >
                        <Grid item xs={4} className={classes.alignCenter}>
                          {device.consignment?.status?.humidity
                            ? device.consignment?.status?.humidity + "%"
                            : ""}
                        </Grid>
                        <Grid item xs={4} className={classes.darkBg}>
                          <CustomTooltip
                            title={Language.translate("Lowest")}
                            arrow
                            placement="top"
                          >
                            <span>0%</span>
                          </CustomTooltip>
                        </Grid>
                        <Grid item xs={4} className={classes.darkBg}>
                          <CustomTooltip
                            title={Language.translate("Highest")}
                            arrow
                            placement="top"
                          >
                            <span>
                              {device.consignment?.settings?.humidity_high !==
                              null
                                ? device.consignment?.settings?.humidity_high +
                                  "%"
                                : "N/A"}
                            </span>
                          </CustomTooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {device.consignment?.status?.battery_charge !== undefined && (
                    <Grid container className={classes.deviceContentLayer}>
                      <Grid item xs={5} className={classes.deviceContentLeft}>
                        {Language.translate("Battery").toUpperCase()}
                      </Grid>
                      <Grid item xs={7} className={classes.deviceContentRight}>
                        {device.consignment?.status?.battery_charge >= 50
                          ? Language.translate("Normal")
                          : Language.translate("Low")}
                      </Grid>
                    </Grid>
                  )}
                  {device.consignment?.status?.temperature ||
                  device.consignment?.status?.humidity ||
                  device.consignment?.status?.battery_charge !== undefined ? (
                    <Grid container className={classes.deviceContentLayer}>
                      <Grid item xs={5} className={classes.deviceContentLeft}>
                        {Language.translate("Last Cargo Report").toUpperCase()}
                      </Grid>
                      <Grid item xs={7} className={classes.deviceContentRight}>
                        {Clock.formatTimestamp(
                          device.consignment?.status?.last_status_reported
                        )}
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              ) : (
                <Grid container className={classes.deviceContentLayer}>
                  <Grid item xs={5} className={classes.deviceContentLeft}>
                    <CustomTooltip
                      title={`${Language.translate(
                        "Last Reported"
                      ).toUpperCase()}`}
                      arrow
                      placement="top"
                    >
                      {Language.translate("Last Reported").toUpperCase()}
                    </CustomTooltip>
                  </Grid>
                  <Grid item xs={7} className={classes.deviceContentRight}>
                    {Clock.formatTimestamp(device.report_timestamp)}
                  </Grid>
                </Grid>
              )}
            </div>
          );
        case deviceType.includes("hawkeye"):
          return (
            <div className={classes.deviceListContainer}>
              {commonRenderedList()}
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Report Age").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {reportAge || "N/A"}
                </Grid>
              </Grid>
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Last Reported").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.report_timestamp &&
                  device.report_timestamp != undefined
                    ? Clock.formatTimestamp(device.report_timestamp)
                    : "N/A"}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Precision").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.vehicle?.precision || "N/A"}
                </Grid>
              </Grid>

              {device.vehicle?.engine_on && (
                <Grid container className={classes.deviceContentLayer}>
                  <Grid item xs={5} className={classes.deviceContentLeft}>
                    {Language.translate("Engine On/Off")}
                  </Grid>
                  <Grid item xs={7} className={classes.deviceContentRight}>
                    {device.vehicle?.engine_on || "N/A"}
                  </Grid>
                </Grid>
              )}

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Odometer").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.vehicle?.odometer || "N/A"}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Event(s)").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {getLatestVehicleReportForDevice(device)?.info?.status
                    ?.hard_breaking && Language.translate("hard braking")}
                  {getLatestVehicleReportForDevice(device)?.info?.status
                    ?.coolant_overheat &&
                    Language.translate("coolant overheat")}
                  {getLatestVehicleReportForDevice(device)?.info?.status
                    ?.device_low_battery && Language.translate("low battery")}
                  {getLatestVehicleReportForDevice(device)?.info?.status
                    ?.oil_overheat && Language.translate("oil overheat")}
                  {!getLatestVehicleReportForDevice(device)?.info?.status
                    ?.oil_overheat &&
                    !getLatestVehicleReportForDevice(device)?.info?.status
                      ?.hard_breaking &&
                    !getLatestVehicleReportForDevice(device)?.info?.status
                      ?.coolant_overheat &&
                    !getLatestVehicleReportForDevice(device)?.info?.status
                      ?.device_low_battery &&
                    "N/A"}
                </Grid>
              </Grid>
            </div>
          );
        case deviceType.includes("wave"):
          return (
            <div className={classes.deviceListContainer}>
              {commonRenderedList()}
              <Grid container className={classes.deviceContentLayer}>
                <Grid
                  item
                  xs={5}
                  className={
                    isChargingBattery
                      ? classes.deviceContentLeftYellowBattery
                      : isRedBattery
                      ? classes.deviceContentLeftRedBattery
                      : classes.deviceContentLeft
                  }
                >
                  {Language.translate("Battery").toUpperCase()}
                </Grid>
                <Grid
                  item
                  xs={7}
                  className={
                    isChargingBattery
                      ? classes.deviceContentRightYellowBattery
                      : isRedBattery
                      ? classes.deviceContentRightRedBattery
                      : classes.deviceContentRight
                  }
                >
                  {device.battery_level || device.battery_level === 0
                    ? device.battery_level + "%"
                    : "N/A"}
                  {isLowerBattery && (
                    <Battery20Icon
                      className={
                        isChargingBattery
                          ? classes.deviceBatteryDark
                          : classes.deviceBattery
                      }
                    />
                  )}
                  {isChargingBattery && (
                    <BatteryCharging80Icon
                      className={classes.deviceBatteryDark}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Report Age").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {reportAge || "N/A"}
                </Grid>
              </Grid>

              {device.latest_sa_timestamp &&
              device.latest_sa_timestamp !== 0 ? (
                <WaveLastSASent device={device} />
              ) : null}

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Last Reported").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {Clock.formatTimestamp(device?.report_timestamp) || "N/A"}
                </Grid>
              </Grid>
            </div>
          );
        case deviceType.includes("nortac orion"):
          return (
            <div className={classes.deviceListContainer}>
              {commonRenderedList()}
              <Grid container className={classes.deviceContentLayer}>
                <Grid
                  item
                  xs={5}
                  className={
                    isChargingBattery
                      ? classes.deviceContentLeftYellowBattery
                      : isRedBattery
                      ? classes.deviceContentLeftRedBattery
                      : classes.deviceContentLeft
                  }
                >
                  {Language.translate("Battery").toUpperCase()}
                </Grid>
                <Grid
                  item
                  xs={7}
                  className={
                    isChargingBattery
                      ? classes.deviceContentRightYellowBattery
                      : isRedBattery
                      ? classes.deviceContentRightRedBattery
                      : classes.deviceContentRight
                  }
                >
                  {device.battery_level || device.battery_level === 0
                    ? device.battery_level + "%"
                    : "N/A"}
                  {isLowerBattery && (
                    <Battery20Icon
                      className={
                        isChargingBattery
                          ? classes.deviceBatteryDark
                          : classes.deviceBattery
                      }
                    />
                  )}
                  {isChargingBattery && (
                    <BatteryCharging80Icon
                      className={classes.deviceBatteryDark}
                    />
                  )}
                </Grid>
              </Grid>

              {device?.report_rate !== null && (
                <Grid container className={classes.deviceContentLayer}>
                  <Grid item xs={5} className={classes.deviceContentLeft}>
                    {Language.translate("Report Interval").toUpperCase()}
                  </Grid>
                  <Grid item xs={7} className={classes.deviceContentRight}>
                    {device?.report_rate || "N/A"}
                  </Grid>
                </Grid>
              )}

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Report Age").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {reportAge || "N/A"}
                </Grid>
              </Grid>

              {device.latest_sa_timestamp &&
              device.latest_sa_timestamp !== 0 ? (
                <WaveLastSASent device={device} />
              ) : null}

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Last Reported").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {Clock.formatTimestamp(device?.report_timestamp) || "N/A"}
                </Grid>
              </Grid>
            </div>
          );

        case deviceType.includes("whisper"):
        default:
          return (
            <div className={classes.deviceListContainer}>
              {commonRenderedList()}

              {device?.report_rate !== null && (
                <Grid container className={classes.deviceContentLayer}>
                  <Grid item xs={5} className={classes.deviceContentLeft}>
                    {Language.translate("Report Interval").toUpperCase()}
                  </Grid>
                  <Grid item xs={7} className={classes.deviceContentRight}>
                    {device?.report_rate || "N/A"}
                  </Grid>
                </Grid>
              )}

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Report Age").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {reportAge || "N/A"}
                </Grid>
              </Grid>
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Last Reported").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.report_timestamp &&
                  device.report_timestamp != undefined
                    ? Clock.formatTimestamp(device.report_timestamp)
                    : "N/A"}
                </Grid>
              </Grid>
            </div>
          );
      }
    }
  };

  if (displayMode === "popup") {
    return (
      <React.Fragment>
        {!state.minimize &&
          deviceTypeFeatures &&
          Object.keys(deviceTypeFeatures)?.length > 0 &&
          renderedListByDeviceType()}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {deviceTypeFeatures &&
          Object.keys(deviceTypeFeatures)?.length > 0 &&
          renderedListByDeviceType()}
      </React.Fragment>
    );
  }
}
