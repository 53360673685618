import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import "./CustomIcon.scss";

const svgIcons = require("./svg_icons.js");

export default function CustomIcon(props) {
  const path = svgIcons[props.id]?.paths;
  return (
    <SvgIcon
      {...props}
      className={`CustomIcon ${props.type}`}
      viewBox={svgIcons[props.id]?.viewbox}
    >
      <path d={path} />
    </SvgIcon>
  );
}
