import React from "react";

//Material UI
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

// Styles
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
}));

export default function FedDetail(props) {
  const data = props.data;

  const classes = useStyles();

  return (
    <Grid container className={classes.detailsContainer}>
      <Grid item xs={12} md={12}>
        <List>
          <ListItem>
            <ListItemText
              className="itemTextFirst"
              primary={Language.translate("Name")}
            />
            <ListItemText className="itemTextSecond" primary={data.name} />
          </ListItem>
          <ListItem>
            <ListItemText
              className="itemTextFirst"
              primary={Language.translate("Username")}
            />
            <ListItemText className="itemTextSecond" primary={data.username} />
          </ListItem>
          <ListItem>
            <ListItemText
              className="itemTextFirst"
              primary={Language.translate("Host URL")}
            />
            <ListItemText className="itemTextSecond" primary={data.host_url} />
          </ListItem>
          <ListItem>
            <ListItemText
              className="itemTextFirst"
              primary={Language.translate("Features")}
            />
            <ListItemText
              className="itemTextSecond"
              primary={data.features
                .split(",")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(", ")}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
}
