import { makeStyles } from "@material-ui/core";
import LeakAddIcon from "@material-ui/icons/LeakAdd";

// Internal
import POIOverlay from "sccPoiOverlay";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    position: "relative",
    display: "inline-block",
  },
  headerIcon: {
    maxWidth: 28,
    maxHeight: 28,
  },
  listIcon: {
    maxWidth: 20,
    maxHeight: 20,
  },
  overlayIcon: {
    position: "absolute",
    bottom: "10%",
    right: "10%",
    fontSize: 12,
    color: theme.palette.colors.black.main,
  },
}));

function PoiIcon(props) {
  const poiId = props.poiId;
  const poiTitle = props.poiTitle;
  const isFederationData = props.isFederationData;
  const header = props.header || false;
  const classes = useStyles();
  const transparentImage =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
  return (
    <div className={classes.iconContainer}>
      <img
        className={header ? classes.headerIcon : classes.listIcon}
        alt={poiTitle ? poiTitle : null}
        src={POIOverlay.getIconById(poiId) || transparentImage}
      />
      {isFederationData && <LeakAddIcon className={classes.overlayIcon} />}
    </div>
  );
}

export default PoiIcon;
