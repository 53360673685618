import * as Yup from "yup";
import baseSchema from "./BaseValidation";
const Language = require("sccLanguage").default;

const schema = Yup.object().shape({
  name: baseSchema(Language).requiredStringMinMax,
  username: baseSchema(Language).requiredStringMinMax,
  password: baseSchema(Language).requiredStringMinMax,
  host_url: baseSchema(Language).urlHttpsOnly,
  features: baseSchema(Language).requiredString,
});

export default schema;
