import { default as Module } from "sccModule";

var Permission = require("sccPermission");
const log = require("loglevel");

/**
 * The base class for FederationModule
 *
 * @class FederationModule
 */
class FederationModule extends Module.Module {
  constructor() {
    super({
      moduleName: "tak",
    });
  }

  init() {
    //using tak for permissions and db stuff
    log.debug(
      "User for Federation module",
      Permission.default.verify("tak", "add")
    );
    if (!Permission.default.verify("tak", "view")) {
      log.debug("User is not permitted for Federation module");
      return Promise.resolve();
    }

    return Module.Module.prototype.init.call(this);
  }
}

export default new FederationModule();
