export const moduleWrapper = (theme) => ({
  container: ({ width }) => ({
    width: width,
    minWidth: width,
    maxWidth: 1000,
    maxHeight: 740,
    overflowX: "hidden",
    overflowY: "hidden",
  }),
  header: {
    backgroundColor: theme.palette.colors.headerFooterBackground.main,
    color: theme.palette.colors.headerFooterText.main,
    width: "100%",
    height: 40,
    "& .iconBox": {
      padding: "5px 9px",
      maxWidth: 40,
      marginRight: 10,
      "& .title": {
        "& .MuiSvgIcon-root": {
          fontSize: "1rem",
        },
      },
    },
    "& .backButton": {
      paddingLeft: 5,
      paddingTop: 5,
    },
    "& .recordsPerPage": {
      paddingTop: 5,
      "& .dropdown": {
        backgroundColor: theme.palette.colors.gray.main,
        width: 130,
        "& .MuiInputBase-root": {
          fontSize: ".9rem",
        },
      },
    },
    "& .filter": {
      paddingTop: 5,
      "& button": {
        backgroundColor: theme.palette.colors.gray.main,
        color: theme.palette.colors.black.main,
        width: 150,
        height: 32,
        maxWidth: 150,
      },
    },
    "& .orderBy": {
      paddingTop: 5,
      marginRight: 10,
      "& button": {
        backgroundColor: theme.palette.colors.gray.main,
        color: theme.palette.colors.black.main,
        width: 135,
        height: 32,
        maxWidth: 150,
      },
    },
    "& .searchFilter": {
      padding: 5,
      "& .textfield": {
        width: 200,
        backgroundColor: theme.palette.colors.white.main,
      },
    },
    "& .closeIcon": {
      padding: "10px 12px",
      "& .MuiSvgIcon-root": {
        fontSize: "1.5rem",
      },
    },
  },
  body: ({ maxHeight }) => ({
    maxWidth: 1000,
    minHeight: 40,
    maxHeight: maxHeight - 250 < 615 ? maxHeight - 250 : 615,
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: theme.palette.colors.white.main,
  }),
  footer: {
    backgroundColor: theme.palette.colors.headerFooterBackground.main,
    color: theme.palette.colors.headerFooterText.main,
    padding: 5,
    width: "100%",
    "& .actionButton": {
      padding: 0,
    },
    "& .gapSpan": {
      width: 8,
      display: "inline-flex",
    },
  },
  noResults: {
    textAlign: "center",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
