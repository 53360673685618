const olFeature = require("ol/Feature").default;
const olProj = require("ol/proj");
const olGeomMultiPoint = require("ol/geom/MultiPoint").default;
const olGeomPoint = require("ol/geom/Point").default;
const olGeomPolygon = require("ol/geom/Polygon").default;
const olGeomCircle = require("ol/geom/Circle").default;
const olGeomLineString = require("ol/geom/LineString").default;
const olOverlay = require("ol/Overlay").default;
const olLayerVector = require("ol/layer/Vector").default;
const olSourceVector = require("ol/source/Vector").default;
const olSourceCluster = require("ol/source/Cluster").default;
const _ = require("lodash");
const log = require("loglevel");
const $ = require("jquery");

const defaultZIndex = 10;
const clusterDistance = 45;

// holds list of overlays that has been created
const MapOverlays = {};

class OlMapOverlay {
  constructor(options) {
    if (!options.id)
      throw new Error("Need to specify an ID for the map overlay");
    if (!options.title)
      throw new Error("Need to specify a title for the map overlay");
    this.OlMap = options.OlMap || require("sccOlMapNew").default;
    this.id = options.id;
    this.title = options.title;
    this.zIndex = options.zIndex || defaultZIndex;
    this.cluster = options.cluster;
    this.isSelectable = options.isSelectable;
    this.popup = options.popup;

    // holds whether or not the map overlay has been initialized
    this.initialized = false;

    // whether or not to hide the overlay from the layer switcher
    this.noSwitcherOption = options.noSwitcherOption;

    this.hideLayerOnStart = options.hideLayerOnStart;

    // holds id of selected feature from this layer
    this.selectedFeature = null;

    // holds ol/source object which features are added to
    this.source = null;

    // field in data to be used as unique key default is 'id'
    this.key = options.uniquekey || "id";

    // holds ol/source object which holds the select icon and the feature selectd
    this.selectIconSource = null;

    // shows whether or not the feature has been locked to the map center
    this.centerLocked = false;

    // showes whether or not selection should be stoped
    this.selectionBlocked = false;

    // holds the list of features ids in a culster when popup is open
    this.clusterFeatureIds = null;

    // event function to run when selection changes
    this.onSelectionChanged = null;

    // current device type selected
    this.currentDeviceTypeSelected = null;

    // current cluster popUpPosition
    this.clusterPopUpPosition = null;
  }

  init(options) {
    // overriding existing properties upon initialization
    if (options && options.OlMap) this.OlMap = options.OlMap;
    if (options && options.hideLayerOnStart)
      this.hideLayerOnStart = options.hideLayerOnStart;
    this.initialized = true;
  }

  /**
   * adds a new overlay to the map and refreshes the layer switcher menu
   * @return {Object} vector source that holds features
   */
  addOverlay() {
    const UserSetting = require("sccUserSetting").default;
    const mapLayerVisibility = UserSetting.get("map_layers");

    const vectorSource = new olSourceVector({
      features: [],
      wrapX: false,
    });

    const cluster = this.cluster;
    const getStyle = this.getStyle;
    const title = this.noSwitcherOption ? null : this.title;
    const zIndex = this.zIndex;
    const layerVisibility = this.hideLayerOnStart
      ? false
      : mapLayerVisibility[title];
    let layer;

    if (cluster) {
      const clusterSource = new olSourceCluster({
        distance: clusterDistance,
        source: vectorSource,
        wrapX: false,
      });

      layer = new olLayerVector({
        title: title,
        visible: layerVisibility,
        source: clusterSource,
        style: getStyle,
        zIndex: zIndex,
      });
    } else {
      layer = new olLayerVector({
        title: title,
        visible: layerVisibility,
        source: vectorSource,
        style: getStyle,
        zIndex: zIndex,
      });
    }

    // set on visibility change event
    layer.on("change:visible", changeLayerHandler, this);

    // adding the layer to the overlay list
    this.OlMap.addCustomOverlay(layer);

    // adding reference to the overlay class that has definded the layer
    layer.Overlay = this;

    // a global object that holds all instances of the OlMapOverlay class
    MapOverlays[this.id] = this;

    this.source = vectorSource;

    /**
     * adds select icon layer if features can be selected
     */
    if (this.isSelectable) {
      this.addSelectIconLayer();
    }

    /**
     * adds popup layer if a popup option is provided
     */
    if (this.popup) {
      this.addPopupOverlay();
    }

    return vectorSource;
  }

  /**
   * gets the data to be loaded on the overlay
   * @interface
   * @return {Array} list of objects to be loaded on the overlay
   */
  getFeatureData() {}

  /**
   * Returns the overlay object by id
   * @param {Number} id overlay id
   * @return {Object} overlay object
   */
  getOverlayById(id) {
    return MapOverlays[id];
  }

  /**
   * refreshes the overlay when an entity on the map is changed
   * @param {Object|Number} data the entity data which contains id, longitude, and latitude
   * @param {Object} removeData data object for the feature to be removed
   */
  refresh(data, removeData) {
    if (!data) {
      if (!data && !removeData) return this.reset();

      log.debug(
        "refreshing",
        this.title,
        "overlay for removed data",
        removeData
      );
      const id = this.getId(removeData);
      const feature = this.getFeature(id);
      if (!feature) return;
      this.removeFeature(feature);
      return;
    }

    let featureId = null;
    if (!_.isObject(data)) {
      log.debug("refreshing", this.title, "overlay for id", data);
      featureId = data;
    } else {
      log.debug("refreshing", this.title, "overlay for data", data);
      featureId = this.getId(data);
    }

    let feature = this.getFeature(featureId);

    // if feature exist, update feature geometry, otherwise create a new feature
    if (feature) {
      // if data is object then it must have geometry info which need to update
      if (_.isObject(data)) {
        const geom = this.buildFeatureGeometry(data);
        feature.setGeometry(geom);
        // if feature is selected the select icon and popup needs to move as well

        this.updateSelectIconGeometry(featureId, geom);
      }

      feature.changed();
    } else {
      this.addFeature({ data: data });
    }
  }

  updateSelectIconGeometry(id, geom) {
    if (this.selectedFeature === id) {
      const selectIconFeature = this.getFeature(0);
      selectIconFeature.setGeometry(geom);
      const coord = this.getFeatureCenter(selectIconFeature);
      this.setPopupPosition(coord);

      // set the center if the feature is locked
      this.centerLocked && this.centerTo(id);
    }
  }

  setPopupPosition(coord) {
    // Check if event is being modifyed (ex.: geofence re-shaping)
    // If yes then do not show popup marker
    if (!_.isNull(this.OlMap.evtType)) return;
    this.popup && this.popupOverlay.setPosition(coord);
  }

  /**
   * resets the overlay
   * @param {Boolean} isHard if true the layer would be refetched from the DB
   */
  reset(isHard) {
    if (this.source == null) return [];
    log.debug("Reseting the", this.title, "overlay. Hard Reset:", !!isHard);

    const $this = this;
    if (isHard) {
      const mapFeatureIds = _.map(this.getFeatures(), (feature) => {
        return feature.getId();
      });

      const newFeatureIds = _.map($this.getFeatureData(), (data) => {
        return $this.getId(data);
      });

      // removing feautres that are not suppose to show anymore
      _.each(_.difference(mapFeatureIds, newFeatureIds), (featureId) => {
        const feature = $this.getFeature(featureId);
        $this.removeFeature(feature);
      });

      // adding features that show show on the map but was not on the map before
      const addFeatureIds = _.difference(newFeatureIds, mapFeatureIds);
      const addFeatureData = _.filter(this.getFeatureData(), (featureData) => {
        return _.indexOf(addFeatureIds, $this.getId(featureData)) > -1;
      });

      this.addFeature({
        data: addFeatureData,
      });
    }

    // refresshing the layers
    this.source.dispatchEvent("change");
    this.selectIconSource.dispatchEvent("change");
  }

  /**
   * @interface
   */
  getStyle() {}

  /**
   * @interface
   */
  getSelectStyle() {}

  /**
   * adds one or more features to the map layer
   * @param {Object} options options object {data: Array|Object}
   */
  addFeature(options) {
    log.debug(
      "adding features to ",
      this.title,
      "overlay with options as",
      options
    );

    const $this = this;
    const source = this.source;
    const dataArray = _.concat([], options.data);
    if (!dataArray.length) {
      log.debug("addFeature: no feature to be added");
      return;
    }

    const features = _.filter(
      dataArray,
      (data) => {
        const id = $this.getId(data);
        if (id === null) {
          return false;
        }
        return true;
      },
      []
    ).map((data) => {
      const id = $this.getId(data);
      const feature = new olFeature({
        geometry: $this.buildFeatureGeometry(data),
        id: id,
      });

      feature.setId(id);

      // sets a property for feature overlay title
      feature.set("overlayId", $this.id);
      feature.set("olMap", $this.OlMap);

      // sets a property for federation Identification
      feature.set("federationServerId", data.federationServerId);

      return feature;
    }, []);

    source.addFeatures(features);
    return features;
  }

  removeFeature(feature) {
    if (!feature) {
      this.deselectFeature(true);
      this.source.clear();
      this.selectIconSource.clear();
      this.setPopupPosition(null);
    } else {
      if (
        feature.getId() === this.selectedFeature ||
        this.isFeatureInSelectedCluster(feature)
      ) {
        this.deselectFeature(true);
        this.hideClusterPopup();
      }
      this.source.removeFeature(feature);
    }
  }

  buildFeatureGeometry(data) {
    const shape = data.shape;
    let line;
    let width = data.width;
    // feature is not a point geometry
    if (shape) {
      const coords = this.getCoords(data);
      switch (shape) {
        case "box":
        case "rectangle":
        case "polygon":
          return new olGeomPolygon([coords]);
        case "circle":
          if (coords.length > 1) {
            line = new olGeomLineString(coords);
            width = line.getLength();
          }

          return new olGeomCircle(coords[0], width);
        case "path":
          return new olGeomLineString(coords);
        default:
          log.error("Could not recognize feature shape", shape);
      }
    } else {
      // feature is a point geometry
      const coord = this.getCoord(data);
      return new olGeomPoint(coord);
    }
  }

  /**
   * gets the coordinates for the center of a feature
   * @param {Object} feature openlayers feature object
   */
  getFeatureCenter(feature, options) {
    const geom = feature.getGeometry();

    // if clicked coordinate is provided, then it would be returened
    // to make sure popup appears on the clicked position
    if (options && options.clickCoordinate) {
      if (geom.getType() != "Point") {
        return options.clickCoordinate;
      }
    }

    return this.getGeometryCenter(geom);
  }

  getGeometryCenter(geom) {
    const extent = geom.getExtent();
    var x = extent[0] + (extent[2] - extent[0]) / 2;
    var y = extent[1] + (extent[3] - extent[1]) / 2;
    return [x, y];
  }

  /**
   * gets openlayers recognized geometry types from platform shape names
   * @param {String} shape platform shape name
   * @return {String} openlayers geometry name
   */
  getShapeType(shape) {
    switch (shape) {
      case "box":
      case "rectangle":
        return "Rectangle";
      case "polygon":
        return "Polygon";
      case "circle":
        return "Circle";
      case "path":
        return "LineString";
      default:
        log.error("Could not recognize the input shape", shape);
    }
  }

  /**
   * centers the map to a feature
   * @param {Number} id feature id
   */
  centerTo(id) {
    const feature = this.getFeature(id);
    const coord = this.getFeatureCenter(feature);
    log.debug(
      "centering map to feature id",
      id,
      "of overlay ",
      this.title,
      " at coordinate ",
      coord
    );
    this.OlMap.setCenterAndZoom(coord);
  }

  /**
   * centers and zooms the map to a feature
   * @param {Number} id feature id
   */
  centerAndZoomTo(id) {
    const zoom = 16;
    let geom;
    // zoom to the extent of multiple features if id is an array of feature IDs
    if (_.isArray(id)) {
      geom = this.buildMultiPointGeometry(id);
    } else {
      const feature = this.getFeature(id);
      if (feature) geom = feature.getGeometry();
    }

    if (geom?.getType() === "Point") {
      const coord = geom.getCoordinates();
      this.OlMap.setCenterAndZoom(coord, zoom);
    } else if (geom) {
      this.zoomToGeometryExtent(geom);
    }
  }

  buildMultiPointGeometry(ids) {
    const filteredIds = _.filter(ids, (fid) => {
      return this.getFeature(fid);
    });
    const coordinates = _.map(filteredIds, (fid) => {
      const feature = this.getFeature(fid);
      return feature.getGeometry().getCoordinates();
    });

    if (coordinates.length > 0) {
      return new olGeomMultiPoint(coordinates);
    }
  }

  zoomToGeometryExtent(geom) {
    const extent = geom.getExtent();

    this.OlMap.zoomToExtent(extent);
  }

  /**
   * extracts id from a data object
   * @param {Object} data data to extract id from
   * @return {Number} id property of the data
   */
  getId(data) {
    // check if data has id
    const id = data && data[this.key];
    if (!id) {
      return null;
    }
    return id;
  }

  /**
   * extracts coordinates from data object
   * @param {Object} data data to extract coordinate from
   * @return {Array} coordinates array
   */
  getCoord(data) {
    const longitude = data && data.longitude;
    const latitude = data && data.latitude;
    // check if data has correct longitude and latitude
    if (longitude == null || latitude == null) {
      throw new Error("Wrong longitude and/or latitude value received");
    }

    const coord = olProj.transform(
      [longitude, latitude],
      "EPSG:4326",
      "EPSG:3857"
    );
    return coord;
  }

  /**
   * returns the list of coordinates for a multi point geometry
   * @param {Object} data data to extract coordinate from
   * @return {Array} array of coordinates array
   */
  getCoords(data) {
    const coordinates = data.coordinates;
    if (!coordinates)
      throw new Error("could not find the coordinates property in data object");

    const $this = this;
    return _.map(coordinates, (coordItem) => {
      return $this.getCoord(coordItem);
    });
  }

  /**
   * get feature by id
   * @param {Number} id id of the feature
   */
  getFeature(id) {
    // since the selected feature is removed from the main layer and added to the select icon layer
    // we search for the feature in both the main layer and in the select icon layer
    return (
      this.source.getFeatureById(
        id != null && !isNaN(id) ? parseInt(id) : id
      ) || this.selectIconSource.getFeatureById(id)
    );
  }

  /**
   * gets all features in the overlay
   *
   * @return {Array} features of the overlay
   */
  getFeatures() {
    // union of all features in main overlay and select overlay
    const features = _.union(
      this.source.getFeatures(),
      this.selectIconSource.getFeatures()
    );
    return _.filter(features, (feature) => {
      // filtering features that have id
      return feature.getId();
    });
  }

  /**
   * gets the id of the feature that has been selected
   * @return {Number} id or null if no feature is selected
   */
  getSelectedId() {
    return this.selectedFeature;
  }

  /**
   * locks map center to a feature
   * @param {Number} id feature id
   */
  lockFeature() {
    log.debug(
      "locking to feature",
      this.selectedFeature,
      "of",
      this.title,
      "overlay"
    );
    this.centerLocked = true;
    const $this = this;
    this.centerTo(this.selectedFeature);
    this.OlMap.registerEvent("moveEndEvents", this.title + "Lock", function () {
      $this.centerTo($this.selectedFeature);
    });
  }

  /**
   * unlocks the locked feature
   */
  unlockFeature() {
    log.debug(
      "unlocking feature",
      this.selectedFeature,
      "of",
      this.title,
      "overlay"
    );
    this.centerLocked = false;
    this.OlMap.unregisterEvent("moveEndEvents", this.title + "Lock");
  }

  /**
   * toggles feature locking feature
   */
  toggleFeatureLock() {
    if (this.centerLocked) {
      this.unlockFeature();
    } else {
      this.lockFeature();
    }
  }

  /**
   * marks a feature as selected
   * @param {Object} feature feature to be selected
   * @param {Boolean} blockSelection blockes further selection of other features
   * @return {Object} selected feature
   */
  selectFeature(feature, options) {
    log.debug(
      "Selected feature id",
      feature.getId(),
      "of",
      this.title,
      "overlay"
    );
    //check if selection is blocked
    if (this.selectionBlocked) {
      log.debug("Feature selection of", this.title, "overlay is blocked");
      return;
    }

    // Sets the info window to open if minimized by user
    this.minimizeWindow = false;
    // deselect already selected
    this.deselectFeature();
    this.setBlockSelection(options.blockSelection);

    const id = feature.getId();

    if (!id) throw new Error("Cannot select feature without ID.");

    this.selectedFeature = id;
    feature.set("selected", true);

    // the select icon is added to a second source layer (iconSourceLayer)
    // the feature's id needs to be set to 0 so it can be styled properly
    const selectIconFeature = feature.clone();
    selectIconFeature.setId(0);
    this.selectIconSource.addFeature(selectIconFeature);

    this.alterPopupHeightByDevice(id, options);

    // the feature that has been selected is removed from the
    // original layer and would be added to the iconSourceLayer
    this.source.removeFeature(feature);
    this.selectIconSource.addFeature(feature);
    // move popup if available
    if (this.selectionBlocked) {
      // not showing popup if selection is blocked
      this.setPopupPosition(null);
    } else {
      const coord = this.getFeatureCenter(feature, options);
      this.setPopupPosition(coord);
      if (this.title === "Geofences" || this.title === "History Trails")
        this.changePopUpPosition(feature, options);
    }

    this.onSelectionChanged &&
      this.onSelectionChanged({
        selectedFeatureId: this.selectedFeature,
        selected: true,
      });

    return feature;
  }

  /**
   * sets the height of the popup and offset according to selected feature
   * @param {Object} options options of selected feature
   */
  changePopUpPosition(feature, options) {
    // let id = null;
    // if(!feature){
    // 	id=	this.selectedFeature;
    // }else {
    // 	id= feature.getId();
    // }

    // this.alterPopupHeightByDevice(id, options);

    this.popupOverlay.setPositioning(options.popUpPosition);
    if (!options.offset) {
      options.offset = [0, 0];
    }

    this.popupOverlay.setOffset(options.offset);
  }

  setBlockSelection(blockSelection) {
    this.selectionBlocked = blockSelection;
  }

  alterPopupHeightByDevice(id, options) {
    var popUpEl = this.popupOverlay.element;
    var popUpElChild = $(popUpEl.firstChild);
    var elId = popUpElChild.attr("id");
    var height;
    /*
     * Set height to popup
     */
    //ASSET (single)
    if (elId === "olMapDevicePopup") {
      const Device = require("sccDevice").default;
      // set the device type that was selected by user
      this.currentDeviceTypeSelected = Device.getDeviceType(
        Device.get(id).type_id
      ).title;
      // Check type
      if (this.currentDeviceTypeSelected === "Container CCU") {
        height = "330px";
      } else if (this.currentDeviceTypeSelected === "Wave") {
        height = "300px";
      } else if (this.currentDeviceTypeSelected === "NORTAC Orion") {
        height = "320px";
      } else if (this.currentDeviceTypeSelected === "Hawkeye 5500") {
        height = "375px";
      }
      // Default height
      else {
        height = "280px";
      }
    }
    //POI (single)
    if (elId === "olMapPoiPopup") {
      height = "135px";
    }
    // GEOFENCE (single)
    if (elId === "olMapGeofencePopup") {
      height = "auto";
    }
    // HISTORY
    if (elId === "olMapHistoryPopup") {
      height = "180px";
    }
    // GEOFENCE
    if (elId === "olMapHistoryTrailPopup") {
      height = "155px";
    }
    //POI (cluster)
    //ASSETS (cluster)

    // when user selects feature from a cluster, the options is null
    if (!options.popUpPosition) {
      options.popUpPosition = this.clusterPopUpPosition;
    }
    //$(".ol-selectable").removeClass("top-left bottom-center bottom-left center-left top-center right-center");
    $(popUpEl).removeClass(
      "top-left bottom-center bottom-left bottom-right center-left top-center top-right center-right"
    );

    $(popUpEl).css("height", height).addClass(options.popUpPosition);

    //$(popUpEl).css("height", "auto").addClass(options.popUpPosition);
  }

  /**
   * selects a feature by its id
   * @param {Number} id feature id
   * @param {Boolean} blockSelection blockes further selection of other features
   * @return {Object} openlayers feature object
   */
  selectFeatureById(id, blockSelection) {
    const feature = this.getFeature(id);
    const options = {
      blockSelection: blockSelection,
    };
    // const Device= require("sccDevice").default;
    return this.selectFeature(feature, options);
  }

  /**
   * processes selection when a cluster is clicked
   * @param {Object} feature cluster feature
   */
  selectCluster(feature, options) {
    const features = feature.get("features");

    this.clusterPopUpPosition = options.popUpPosition;
    this.changePopUpPosition(features[0], options);
    if (features.length === 1) {
      this.selectFeature(features[0], options);
    } else {
      this.showClusterPopup(feature, options);
    }
  }

  /**
   * shows the popup of thel list of features in the cluster
   * @param {Array} features array of features in the cluster
   */
  showClusterPopup(feature) {
    const features = feature.get("features");
    log.debug(
      "showing features",
      features,
      "in a cluster of",
      this.title,
      "overlay"
    );

    this.clusterFeatureIds = _.map(features, (feature) => {
      return feature.getId();
    });

    const $this = this;
    this.onSelectionChanged &&
      this.onSelectionChanged({
        selectedFeatureId: $this.clusterFeatureIds,
        selected: true,
      });

    // register event to remove popup if zoom level changes
    this.OlMap.registerEvent(
      "zoomChangeEvents",
      this.title + "Popup",
      function () {
        $this.hideClusterPopup();
      }
    );

    // move popup if available
    const coord = this.getFeatureCenter(feature);

    this.cluster &&
      this.cluster.popupContainerId &&
      this.clusterPopupOverlay.setPosition(coord);
  }

  /**
   * checks if a given feature is in the list of selected cluster
   * @param {Object} feature OpenLayers feature object
   * @return {Boolean} whether or not the feature is in the selected cluster
   */
  isFeatureInSelectedCluster(feature) {
    let check = false;
    _.each(this.clusterFeatureIds, (id) => {
      if (id === feature.getId()) {
        check = true;
        return false;
      }
    });
    return check;
  }

  /**
   * hids the cluster popup
   */
  hideClusterPopup() {
    if (
      !this.cluster ||
      !this.cluster.popupContainerId ||
      !this.clusterPopupOverlay
    )
      return;

    log.debug("hiding cluster popup of", this.title, "overlay");
    this.cluster &&
      this.cluster.popupContainerId &&
      this.clusterPopupOverlay.setPosition(null);
    this.clusterFeatureIds = null;

    // unregister the pupup removal event
    this.OlMap.unregisterEvent("zoomChangeEvents", this.title + "Popup");
  }

  /**
   * returns the list of ids of features in the clicked cluster
   * @return {Array} list of feature ids
   */
  getClusterFeatureIds() {
    return this.clusterFeatureIds;
  }

  /**
   * deselects the feature that is selected
   * @param {Boolean} forceDeselect forces feature to deselect if selection is blocked
   */
  deselectFeature(forceDeselect) {
    if (forceDeselect) {
      log.debug(
        "Selected feature id",
        this.selectedFeature,
        " of overlay",
        this.title,
        "is force deselected"
      );
      this.setBlockSelection(false);
    }

    if (!this.selectedFeature) return;

    if (this.selectionBlocked && !forceDeselect) {
      log.debug(
        "Cannot deselect feature id",
        this.selectedFeature,
        ". Overlay",
        this.title,
        "selction is blocked"
      );
      return;
    }
    log.debug(
      "deselecting feature id",
      this.selectedFeature,
      "of the",
      this.title,
      "overlay"
    );

    this.unlockFeature();
    const id = this.selectedFeature;
    const feature = this.getFeature(id);
    this.source.addFeature(feature);
    feature.set("selected", false);
    this.setPopupPosition(null);

    const GeofenceOverlay = require("sccGeofenceOverlay").default;
    if (!GeofenceOverlay.floatingFeatureOnMap) {
      this.selectIconSource.clear();
    }

    this.onSelectionChanged &&
      this.onSelectionChanged({
        selectedFeatureId: this.selectedFeature,
        selected: false,
      });

    this.selectedFeature = null;
  }

  /**
   * addes the select icon to the map when icon is selected
   * @param {Object} overlays map overlays function
   */
  addSelectIconLayer() {
    // increasing the selection layer zIndex to show on top of other layers
    // however, the exiting hierarchy between layers would be the same among
    // selected features of different layers
    const zIndex = this.zIndex + 50;
    this.selectIconSource = new olSourceVector({
      features: [],
      wrapX: false,
    });
    const $this = this;
    const selectIconLayer = new olLayerVector({
      visible: true,
      source: $this.selectIconSource,
      style: $this.getSelectStyle,
      zIndex: zIndex,
    });

    // adding the select icon layer to the overlay list
    this.OlMap.addCustomOverlay(selectIconLayer);
  }

  /**
   * adds a new poupup overlay to the map
   */
  addPopupOverlay() {
    const containerId = this.popup.containerId;
    const offset = this.popup.offset || [0, 0];
    const container = document.getElementById(containerId);
    //if(!container) throw new Error("Could not find the popup container: "+ containerId);

    this.popupOverlay = new olOverlay({
      element: container,
      offset: offset,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });
    this.OlMap.addMapOverlay(this.popupOverlay);

    // add cluster popup overlay if clustering is set and container ID is provided
    if (this.cluster && this.cluster.popupContainerId) {
      const clusterContainerId = this.cluster.popupContainerId;
      const clusterOffset = this.cluster.offset || [0, 0];
      const clusterContainer = document.getElementById(clusterContainerId);
      //if(!clusterContainer) throw new Error("Could not find the cluster popup container");

      this.clusterPopupOverlay = new olOverlay({
        element: clusterContainer,
        offset: clusterOffset,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      this.OlMap.addMapOverlay(this.clusterPopupOverlay);
    }
  }

  on(event, func) {
    if (event === "selectionchanged") {
      this.onSelectionChanged = func;
    } else {
      throw new Error(event + " is not a recognized event type");
    }
  }
}

function changeLayerHandler(event) {
  const UserSetting = require("sccUserSetting").default;
  const mapLayerVisibility = UserSetting.get("map_layers");
  const visStatusObj = event.target.values_;
  const layerName = visStatusObj.title;
  const isVisible = visStatusObj.visible;
  mapLayerVisibility[layerName] = isVisible;
  UserSetting.update({ map_layers: mapLayerVisibility }).then(function (data) {
    log.debug("Map Overlay Visibility Updated", data);
  });

  switch (layerName) {
    case "POI":
      MapOverlays.poi_overlay.deselectFeature();
      MapOverlays.poi_overlay.hideClusterPopup();
      break;
    case "Assets":
      MapOverlays.device_overlay.deselectFeature();
      MapOverlays.device_overlay.hideClusterPopup();
      break;
    case "Geofences":
      MapOverlays.geofence_overlay.deselectFeature();
      MapOverlays.geofence_overlay.hideClusterPopup();
      break;
    default:
      break;
  }
}

/**
 * deselects all features of all
 */
export function deselectAllFeatures() {
  _.each(MapOverlays, (overlay) => {
    overlay.isSelectable && overlay.deselectFeature();
    overlay.hideClusterPopup();
  });
}
/**
 * selects the first feature of the list of features returned by click event
 * @param {Array} features list of features at clicked position
 */
export function selectFeature(features, options) {
  let feature = null;

  // get the first feature that is either cluseter of a feature with id
  _.each(features, (feat) => {
    if (feat.get("features") || feat.getId()) {
      feature = feat;
      return false;
    }
  });

  // not clicked on a valid feature
  if (!feature) return;

  // featuers are clustered
  if (feature.get("features")) {
    const overlayId = feature.get("features")[0].get("overlayId");
    const overlay = MapOverlays[overlayId];
    // select if overlay is set to selectable
    overlay?.isSelectable && overlay?.selectCluster(feature, options);
  } else {
    // features are not clustered
    const overlayId = feature.get("overlayId");
    const overlay = MapOverlays[overlayId];
    overlay?.isSelectable && overlay?.selectFeature(feature, options);
  }
}

//module.exports= OlMapOverlay;
export default { OlMapOverlay };
