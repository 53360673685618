import React, { useContext } from "react";

// Material-UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Popover from "@material-ui/core/Popover";
import PersonIcon from "@material-ui/icons/Person";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import IconButton from "@material-ui/core/IconButton";
import LeakAddIcon from "@material-ui/icons/LeakAdd";
import { makeStyles } from "@material-ui/core/styles";
import { Badge } from "@material-ui/core";

// Internal
import Profile from "sccProfile";
import Menu from "sccMenu";
import Language from "sccLanguage";
import CustomDialog from "@Components/CustomDialog/index.js";
import { FederationDataContext } from "@Modules/federation_data/context/FederationDataContext";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    height: 50,
    width: 50,
    fontSize: "2em",
    float: "right",
    border: "1px solid transparent",
    borderRadius: 0,
    position: "relative",
    background: "rgba(255, 255, 255, 1) !important",
    "& .MuiSvgIcon-root": {
      fontSize: 36, //Icon size
      color: theme.palette.colors.black.main,
    },
  },
  profileMenuItem: {
    position: "relative",
    margin: "auto",
    padding: "10px 15px",
    backgroundColor: theme.palette.colors.white.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: 24,
      paddingRight: 5,
    },
  },
  profileMenuGroup: {
    margin: 0,
    paddingLeft: 0,
    listStyle: "none",
  },
  profileMenuContainer: {
    "& .MuiPaper-root": {
      padding: 0,
      width: 230,
      height: "auto",
      top: "60px !important",
      right: 5,
      left: "unset !important",
      float: "right",
      background: theme.palette.colors.white.main,
      boxShadow:
        "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4)",
    },
    "& .MuiList-padding": {
      paddingTop: 0,
      paddingBottom: 0,
    },

    "& .MuiTypography-body": {
      fontSize: 14,
    },

    "& .MuiSvgIcon-root": {
      fontSize: 18,
    },

    "& .MuiListItemIcon-root": {
      minWidth: 28,
    },
  },
  fedBadge: {
    right: 3,
    top: 3,
    backgroundColor: "transparent",
  },
}));

export default function ProfileButtton() {
  const [fedState] = useContext(FederationDataContext);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  function okAction() {
    Menu.logout();
    setDialogOpen(false);
  }
  function cancelAction() {
    setDialogOpen(false);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "profile-popover" : undefined;

  return (
    <div>
      <IconButton
        className={classes.menuButton}
        variant="contained"
        color="default"
        onClick={handleClick}
      >
        <Badge
          color="primary"
          invisible={!fedState.federationConnection?.federationServerId}
          badgeContent={<LeakAddIcon style={{ fontSize: 24 }} />}
          classes={{
            badge: classes.fedBadge,
          }}
        >
          <PersonIcon />
        </Badge>
      </IconButton>

      <Popover
        className={classes.profileMenuContainer}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List component="nav" aria-label="main mailbox folders">
          <ListItem>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                Profile.get("first_name") + " " + Profile.get("last_name")
              }
            />
          </ListItem>
          <Divider />
          <ListItem button onClick={handleDialogOpen}>
            <ListItemIcon>
              <PowerSettingsNewIcon />
            </ListItemIcon>
            <ListItemText primary={Language.translate("Logout")} />
          </ListItem>
        </List>
      </Popover>

      <CustomDialog
        type="logout"
        open={dialogOpen}
        onCancelAction={cancelAction}
        onOkAction={okAction}
        text={Language.translate("Are you sure you want to logout now?")}
      />
    </div>
  );
}
