import React, { Component } from "react";
import DataDisplayButtonGroup from "./DataDisplayButtonGroup";
import GeofenceInfo from "./GeofenceInfo";
import DataDisplayHeader from "./DataDisplayHeader";
import Paper from "@material-ui/core/Paper";
import MinimizeContextProvider from "../context/MinimizeContext.js";
import GeofenceSetting from "@Modules/geofence/scripts/GeofenceSetting";
import _ from "lodash";

const Geofence = require("sccGeofence").default;
const GeofenceOverlay = require("sccGeofenceOverlay").default;

export default class DataDisplay extends Component {
  constructor() {
    super();
    this.state = {
      device: null,
    };

    if (!DataDisplay.publicInstance) {
      DataDisplay.publicInstance = this;
    }
  }

  static publicInstance;

  static updateFeatureSelected() {
    setTimeout(() => {
      DataDisplay.publicInstance.setFeatureSelected();
    });
  }

  setFeatureSelected() {
    this.setState({ device: Geofence.get(GeofenceOverlay.getSelectedId()) });
  }

  render() {
    const { device } = this.state;
    // checks if the geofence has an active colour id, if true, merges colour hex and colour label to device obj and returns it. otherwise just returns device unchanged.
    const data = () => {
      let geoType = device.inclusive ? "inclusive" : "exclusive";
      let mergedData = device;
      let emptyObjCheck = _.isEmpty(GeofenceSetting.get(geoType + "_palette"));

      if (!emptyObjCheck) {
        let usedPalette = JSON.parse(GeofenceSetting.get(geoType + "_palette"));
        let index = _.findIndex(usedPalette, {
          id: mergedData.custom_colour_id,
        });
        //_.findIndex returns -1 when no indecies were found
        let noneFound = -1;

        if (index !== noneFound) {
          const entry = usedPalette[index];
          mergedData.hex = entry.hex;
          mergedData.label = entry.label;
        }
      }
      //Only for federation server entries
      if (device.custom_colour_details) {
        mergedData.hex = device.custom_colour_details.hex;
      }
      return mergedData;
    };
    return (
      <MinimizeContextProvider>
        <Paper elevation={3} id={"olMapGeofencePopup"}>
          {device && device?.id ? (
            <React.Fragment>
              <DataDisplayHeader device={device} />
              {
                <React.Fragment>
                  <DataDisplayButtonGroup device={device} />
                  <GeofenceInfo device={data()} />
                </React.Fragment>
              }
            </React.Fragment>
          ) : null}
        </Paper>
      </MinimizeContextProvider>
    );
  }
}
