import React, { useContext, useEffect, useState } from "react";

//External
import { useFormikContext } from "formik";

// Material-UI
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// Components
import CustomTextField from "@Components/CustomTextField";
import CustomCheckbox from "@Components/CustomCheckbox";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleForm } from "@Styles/ModuleForm";

// Internal
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  detailsContainer: {
    margin: 0,
    backgroundColor: theme.palette.colors.white.main,
    color: theme.palette.colors.black.main,
    "& .MuiListItem-gutters": {
      // paddingLeft: 6,
      // paddingRight: 6,
    },
    "& .MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
      alignItems: "start",
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
      "& .MuiChip-root": {
        paddingLeft: 2,
        flex: "0 1 auto",
      },
    },
    "& .pullRight": {
      display: "flex",
      justifyContent: "right",
      marginRight: "1em",
      marginBottom: "0em",
    },
    "& .itemTextModule": {
      paddingBottom: 14,
    },
    "& .itemTextTitle": {
      // marginTop: 10,
      width: "96%",
      marginLeft: 16,
      paddingLeft: 4,
    },
    "& .itemTextFirst": {
      background: theme.palette.colors.gray.main,
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      padding: "4px 8px",
      width: "40%",
    },
    "& .itemTextSecond": {
      width: "60%",
      marginLeft: -1,
      minHeight: "27px",
      "& .MuiChip-root": {
        marginRight: 5,
      },
    },
  },
}));

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function TakForm() {
  const newTak = {
    name: "",
    host_url: "",
    typeID: 1,
    features: "",

    input_port: null,
    api_port: null,

    username: "",
    password: "",

    openfire_url: "",
    openfire_port: null,

    client_filename: "",
    truststore_filename: "",

    client_password: "",
    truststore_password: "",

    client_file_location: "",
    truststore_file_location: "",

    client_file_type: "",
    truststore_file_type: "",

    client_file_size: null,
    truststore_file_size: null,
  };

  const [mwState, setMwState] = useContext(ModuleWrapperContext);

  const formik = useFormikContext();
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;
  const moduleItemData = formik.values;
  const classes = useStyles();

  useEffect(() => {
    if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
      const TakData = mwState.moduleItemData;
      setMwState((p) => ({
        ...p,
        moduleItemData: {
          ...newTak,
          ...TakData,
        },
        moduleItemDataOriginal: { ...newTak, ...TakData },
      }));
    }
  }, [mwState.wrapperDisplayMode]);

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} className={classes.detailsContainer}>
        <List>
          {/* General */}
          <div className="itemTextModule">
            <p className="pullRight">{`* ${Language.translate(
              "Required fields"
            )}`}</p>
            <ListItem className="itemTextTitle">
              <ListItemText primary={Language.translate("General")} />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Name*")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.name}
                name="name"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Username")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.username}
                name="username"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Password")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.password}
                name="password"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Host URL")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.host_url}
                name="host_url"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.host_url && Boolean(formik.errors.host_url)
                }
                helperText={formik.touched.host_url && formik.errors.host_url}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Input Port")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.input_port}
                name="input_port"
                // disabled={moduleItemData.input_port}
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.input_port && Boolean(formik.errors.input_port)
                }
                helperText={
                  formik.touched.input_port && formik.errors.input_port
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("API Port")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.api_port}
                name="api_port"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.api_port && Boolean(formik.errors.api_port)
                }
                helperText={formik.touched.api_port && formik.errors.api_port}
              />
            </ListItem>
          </div>

          {/* Openfire Section */}
          <div className="itemTextModule">
            <ListItem className="itemTextTitle">
              <ListItemText primary={Language.translate("Openfire")} />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("URL")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.openfire_url}
                name="openfire_url"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.openfire_url &&
                  Boolean(formik.errors.openfire_url)
                }
                helperText={
                  formik.touched.openfire_url && formik.errors.openfire_url
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Port")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.openfire_port}
                name="openfire_port"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.openfire_port &&
                  Boolean(formik.errors.openfire_port)
                }
                helperText={
                  formik.touched.openfire_port && formik.errors.openfire_port
                }
              />
            </ListItem>
          </div>

          {/* Client Certificate Section */}
          <div className="itemTextModule">
            <ListItem className="itemTextTitle">
              <ListItemText
                primary={Language.translate("Client Certificate")}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Username")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.client_filename}
                name="client_filename"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.client_filename &&
                  Boolean(formik.errors.client_filename)
                }
                helperText={
                  formik.touched.client_filename &&
                  formik.errors.client_filename
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Password")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.client_password}
                name="client_password"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.client_password &&
                  Boolean(formik.errors.client_password)
                }
                helperText={
                  formik.touched.client_password &&
                  formik.errors.client_password
                }
              />
            </ListItem>

            {/* ***Location */}
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("File Location")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.client_file_location}
                name="client_file_location"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.client_file_location &&
                  Boolean(formik.errors.client_file_location)
                }
                helperText={
                  formik.touched.client_file_location &&
                  formik.errors.client_file_location
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("File Size")}
              />
              <CustomTextField
                // id='outlined-number'
                // type='number'
                className="itemTextSecond"
                value={moduleItemData.client_file_size}
                onClick={() =>
                  console.log(typeof moduleItemData.client_file_size)
                }
                name="client_file_size"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.client_file_size &&
                  Boolean(formik.errors.client_file_size)
                }
                helperText={
                  formik.touched.client_file_size &&
                  formik.errors.client_file_size
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("File Type")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.client_file_type}
                name="client_file_type"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.client_file_type &&
                  Boolean(formik.errors.client_file_type)
                }
                helperText={
                  formik.touched.client_file_type &&
                  formik.errors.client_file_type
                }
              />
            </ListItem>
          </div>

          {/* Truststore Section */}
          <div className="itemTextModule">
            <ListItem className="itemTextTitle">
              <ListItemText
                primary={Language.translate("Truststore Certificate")}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Username")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.truststore_filename}
                name="truststore_filename"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.truststore_filename &&
                  Boolean(formik.errors.truststore_filename)
                }
                helperText={
                  formik.touched.truststore_filename &&
                  formik.errors.truststore_filename
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Password")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.truststore_password}
                name="truststore_password"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.truststore_password &&
                  Boolean(formik.errors.truststore_password)
                }
                helperText={
                  formik.touched.truststore_password &&
                  formik.errors.truststore_password
                }
              />
            </ListItem>

            {/* ***Location */}
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("File Location")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.truststore_file_location}
                name="truststore_file_location"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.truststore_file_location &&
                  Boolean(formik.errors.truststore_file_location)
                }
                helperText={
                  formik.touched.truststore_file_location &&
                  formik.errors.truststore_file_location
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("File Size")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.truststore_file_size}
                name="truststore_file_size"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.truststore_file_size &&
                  Boolean(formik.errors.truststore_file_size)
                }
                helperText={
                  formik.touched.truststore_file_size &&
                  formik.errors.truststore_file_size
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("File Type")}
              />
              <CustomTextField
                className="itemTextSecond"
                value={moduleItemData.truststore_file_type}
                name="truststore_file_type"
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.truststore_file_type &&
                  Boolean(formik.errors.truststore_file_type)
                }
                helperText={
                  formik.touched.truststore_file_type &&
                  formik.errors.truststore_file_type
                }
              />
            </ListItem>
          </div>
        </List>
      </Grid>
    </React.Fragment>
  );
}
