import React from "react";

// External
import _ from "lodash";

// Material-UI
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import LeakAddIcon from "@material-ui/icons/LeakAdd";

// Internal
import Device from "sccDevice";
import DeviceAlertBar from "../../DataDisplay/DeviceAlertBar";
import { default as DeviceDataDisplay } from "../../DataDisplay";
import DeviceHistoryAlertBar from "../../DataDisplay/DeviceHistoryAlertBar";
import DeviceIcon from "../../DeviceIcon";
import DeviceOverlay from "sccDeviceOverlay";
import HistoryOverlay from "sccHistoryOverlay";
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  clusterItemIcon: {
    fontSize: 12,
  },
  deviceBody: {
    "& .MuiList-root": {
      padding: "0",
      maxHeight: "300px",
      "& .MuiListItem-root": {
        border: `1px solid ${theme.palette.colors.gray.main}`,
        padding: "0",
        marginTop: "-1px",
        "& .ClusterListListItemText-first": {
          background: theme.palette.colors.gray.main,
          margin: "0",
          padding: "4px 8px",
          width: "55px",
          "& .MuiListItemText-primary": {
            fontSize: "0.9rem",
          },
        },
        "& .ClusterListListItemText-second": {
          margin: "0",
          textAlign: "right",
          padding: "4px 8px",
          width: "85px",
          "& .MuiListItemText-primary": {
            fontSize: "0.9rem",
          },
        },
      },
    },

    "& .clusterContainer": {
      padding: "0px 8px",
      cursor: "pointer",
      minHeight: "37px",
      "&:hover": {
        backgroundColor: theme.palette.colors.gray.main,
      },
    },

    "& .clusterItemIcon": {
      fontSize: "12px",
    },

    "& .clusterItemLeft": {
      fontSize: "20pt",
      paddingRight: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    "& .clusterItemName": {
      flex: "1",
    },

    "& .clusterDeviceItem": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    "& .clusterDeviceListing": {
      maxHeight: "300px",
      overflowY: "auto",
      marginTop: "0",
    },
  },
  iconContainer: {
    position: "relative",
    display: "inline-block",
  },
  overlayIcon: {
    position: "absolute",
    bottom: "5%",
    right: "-25%",
    fontSize: 12,
    color: theme.palette.colors.black.main,
  },
}));

export default function ClusterList(props) {
  const devices = props.devices;
  const isHistory = props.history === "1";
  const classes = useStyles();

  function handleClick(id) {
    if (isHistory) {
      HistoryOverlay.deselectFeature();
      HistoryOverlay.hideClusterPopup();
      HistoryOverlay.selectFeatureById(id, false);
      DeviceDataDisplay.updateFeatureSelected();
    } else {
      DeviceOverlay.deselectFeature();
      DeviceOverlay.hideClusterPopup();
      DeviceOverlay.selectFeatureById(id, false);
      DeviceDataDisplay.updateFeatureSelected();
    }
    return true;
  }

  const deviceList = DeviceOverlay.getFeatureData();

  return isHistory ? (
    <div className={classes.deviceBody}>
      <ul className="clusterDeviceListing">
        {!_.isEmpty(devices) ? (
          devices.map((deviceId) => {
            const deviceInfo = Device.get(deviceId);
            return (
              <React.Fragment key={deviceInfo.id}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  className="clusterContainer"
                  key={deviceInfo.id}
                  onClick={() => handleClick(deviceInfo.id)}
                >
                  <Grid item className="clusterItemLeft">
                    <DeviceIcon
                      device={deviceInfo}
                      isFederationData={Boolean(deviceInfo.federationServerId)}
                    />
                  </Grid>
                  <Grid item className="clusterItemName">
                    {deviceInfo.name}
                  </Grid>
                  <Grid item className="clusterDeviceItem">
                    <DeviceHistoryAlertBar device={deviceInfo} />
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })
        ) : (
          <div align="center">{Language.translate("No Results!")}</div>
        )}
      </ul>
    </div>
  ) : (
    <div className={classes.deviceBody}>
      <ul className="clusterDeviceListing">
        {!_.isEmpty(devices) ? (
          devices.map((data) => {
            var deviceIndex = _.findIndex(deviceList, (device) => {
              return device.id === data;
            });
            var deviceData = deviceList[deviceIndex];
            const isFederationData = Boolean(deviceData?.federationServerId);

            return deviceData !== undefined && deviceData ? (
              <React.Fragment key={deviceData.id}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  className="clusterContainer"
                  key={deviceData.id}
                  onClick={() => handleClick(deviceData.id)}
                >
                  <Grid item className="clusterItemLeft">
                    <DeviceIcon
                      device={deviceData}
                      isFederationData={isFederationData}
                    />
                  </Grid>
                  <Grid item className="clusterItemName">
                    {deviceData.name}
                  </Grid>
                  <Grid item className="clusterDeviceItem">
                    <DeviceAlertBar device={deviceData} />
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : null;
          })
        ) : (
          <div align="center">{Language.translate("No Results!")}</div>
        )}
      </ul>
    </div>
  );
}
