import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Language = require("sccLanguage").default;

const schema = Yup.object().shape({
  name: baseSchema(Language).requiredStringMinMax,

  host_url: baseSchema(Language).url,
  input_port:
    baseSchema(Language).notRequiredPortMin4DigitNumberMax5DigitsNumber,
  api_port: baseSchema(Language).notRequiredPortMin4DigitNumberMax5DigitsNumber,

  username: baseSchema(Language).notRequiredStringMinMax,
  password: baseSchema(Language).notRequiredStringMinMax,

  openfire_url: baseSchema(Language).url,
  openfire_port:
    baseSchema(Language).notRequiredPortMin4DigitNumberMax5DigitsNumber,

  client_filename: baseSchema(Language).notRequiredLongStringMinMax,
  client_password: baseSchema(Language).notRequiredLongStringMinMax,

  truststore_filename: baseSchema(Language).notRequiredLongStringMinMax,
  truststore_password: baseSchema(Language).notRequiredLongStringMinMax,

  client_file_location: baseSchema(Language).notRequiredLongStringMinMax,
  truststore_file_location: baseSchema(Language).notRequiredLongStringMinMax,

  client_file_type: baseSchema(Language).notRequiredLongStringMinMax,
  truststore_file_type: baseSchema(Language).notRequiredLongStringMinMax,

  client_file_size: baseSchema(Language).notRequiredNumberPositiveIntNullable,
  truststore_file_size:
    baseSchema(Language).notRequiredNumberPositiveIntNullable,
});

export default schema;
