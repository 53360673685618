import { makeStyles } from "@material-ui/core";
import LeakAddIcon from "@material-ui/icons/LeakAdd";

// Internal
import Device from "sccDevice";
import Images from "sccImage";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    position: "relative",
    display: "inline-block",
  },
  headerIcon: {
    maxWidth: 28,
    maxHeight: 28,
  },
  listIcon: {
    maxWidth: 20,
    maxHeight: 20,
  },
  overlayIcon: {
    position: "absolute",
    bottom: "10%",
    right: "10%",
    fontSize: 12,
    color: theme.palette.colors.black.main,
  },
}));

function DeviceIcon(props) {
  const device = props.device;
  const isFederationData = props.isFederationData;
  const header = props.header || false;
  const classes = useStyles();
  const transparentImage =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAIVJREFUaEPt0rENACAMxMBk/6UZ4goUyfR+gc3O8bPH7z894HfBClQADfSFUCDjFWCFOFABFMh4BVghDlQABTJeAVaIAxVAgYxXgBXiQAVQIOMVYIU4UAEUyHgFWCEOVAAFMl4BVogDFUCBjFeAFeJABVAg4xVghThQARTIeAVYIQ6cL/AAmvUAMfJYo3UAAAAASUVORK5CYII=";
  return (
    <div className={classes.iconContainer}>
      <img
        className={header ? classes.headerIcon : classes.listIcon}
        alt={Images.get(Device.getDeviceType(device.type_id).image_id).name}
        src={
          Images.getImageSource(
            Device.getDeviceType(device.type_id).image_id
          ) || transparentImage
        }
      />
      {isFederationData && <LeakAddIcon className={classes.overlayIcon} />}
    </div>
  );
}

export default DeviceIcon;
