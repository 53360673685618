import React, { useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import Alert from "sccAlert";
import Historic from "sccHistory";
import Reports from "sccReport";
import ExtendedMapMenu from "sccExtendedMapMenu";
import Message from "sccMessage";
import SyncMenu from "sccSyncMenu";
import Sync from "sccSync";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomIcon from "@Components/CustomIcon";

const useStyles = makeStyles((theme) => ({
  menuButtonModules: {
    float: "left",
    width: "calc(33.33% - 11px)",
    border: `1px solid ${theme.palette.colors.gray.main}`,
    marginTop: -1,
    marginLeft: -1,
    listStyle: "none",
    fontSize: 10,
    textAlign: "center",
    padding: "8px 5px 5px 5px",
    height: 60,
    "& .MuiSvgIcon-root": {
      fontSize: 14, //Icon size
    },
  },
  title: {
    whiteSpace: "nowrap" /* Prevents text wrapping to the next line */,
    overflow: "hidden" /* Hides the overflowing text */,
    textOverflow: "ellipsis" /* Adds an ellipsis at the end of the text */,
    display: "block" /* Ensures the block-level rendering */,
    width: "calc(99%)",
  },
}));

const MenuButtonItem = (props) => {
  const [mwState] = useContext(ModuleWrapperContext);
  const classes = useStyles();

  const handleDialogOpenClose = (e) => {
    mwState.setDialogOkAction(() => okActionClose(e));
    mwState.setDialogCancelAction(() => cancelAction());
    mwState.setDialogOpen(true);
  };
  function okActionClose(e) {
    handleClick(e);
    mwState.setDialogOpen(false);
  }
  function cancelAction() {
    mwState.setDialogOpen(false);
  }

  const handleClick = (event) => {
    // close menuList Popper
    props.closeMenuListPopper();

    switch (props.tab) {
      case "history":
        Historic.openWindow();
        break;
      case "reports":
        Reports.openWindow();
        break;
      case "Maps Code":
        ExtendedMapMenu.openWindow();
        break;
      case "support":
        openSupportWindow();
        break;
      case "permission":
        const objectPermission = _.cloneDeep(props.object);
        //sending the cloned object so that we do not change the Permissions module itself
        objectPermission.module._data = objectPermission.module._allRoles;
        mwState.openModuleWrapperPopper(objectPermission);
        break;
      case "MSG":
        //special messaging module case
        mwState.openModuleWrapperPopperMessaging(props.object);
        break;
      default:
        mwState.openModuleWrapperPopper(props.object);
        break;
    }
  };

  return (
    <div>
      <li
        className={classes.menuButtonModules}
        onClick={(e) => {
          !mwState.moduleFormHasChanges
            ? handleClick(e)
            : handleDialogOpenClose();
        }}
      >
        {props.tab === "Sync" ? (
          <div>
            <Badge
              color="secondary"
              badgeContent={SyncMenu.getCountOfStatus(Sync.get()).syncing_count}
              max={9999}
            >
              <CustomIcon id={`icon-${props.icon}`} />
            </Badge>
            <br />
            {props.shortTitle}
          </div>
        ) : props.tab === "Alert" ? (
          <div>
            <Badge
              color="secondary"
              badgeContent={Alert.get() ? Object.keys(Alert.get()).length : 0}
              max={9999}
            >
              <CustomIcon id={`icon-${props.icon}`} />
            </Badge>
            <br />
            {props.shortTitle}
          </div>
        ) : props.tab === "MSG" ? (
          <div>
            <Badge
              color="secondary"
              badgeContent={Message.getMessageInfo().unread_count}
              max={9999}
            >
              <CustomIcon id={`icon-${props.icon}`} />
            </Badge>
            <br />
            {props.shortTitle}
          </div>
        ) : props.extLink ? (
          <div>
            <CustomIcon id={"icon-external-link"} type={"external-link"} />
            <CustomIcon id={`icon-${props.icon}`} />
            <br />
            {props.shortTitle}
          </div>
        ) : (
          <div className={classes.title}>
            <CustomIcon id={`icon-${props.icon}`} />
            <br />
            {props.shortTitle}
          </div>
        )}
      </li>
    </div>
  );
};
const openSupportWindow = function () {
  window.open(
    "https://nortacdefence.atlassian.net/servicedesk/customer/portals",
    "",
    "width=500,height=500"
  );
};

export default MenuButtonItem;
