import React, { useContext, useEffect, useState } from "react";

//External
import { useFormikContext } from "formik";

// Material-UI
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// Styles
import { moduleForm } from "@Styles/ModuleForm";

// Internal
import Language from "sccLanguage";
import CustomTextField from "@Components/CustomTextField";
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomSwitch from "@Components/CustomSwitch";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { FederationDataContext } from "@Modules/federation_data/context/FederationDataContext";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  checkbox: {
    "& .MuiFormControlLabel-label": {
      "margin-left": -5,
      "margin-right": 20,
    },
  },
  errorText: {
    color: "#f44336",
    margin: 0,
    fontSize: "0.75rem",
    marginTop: 8,
    textAlign: "left",
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
}));

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function FedForm(props) {
  const newFed = {
    name: "",
    host_url: "",
    typeID: 2,
    features: "",

    input_port: null,
    api_port: null,

    username: "",
    password: "",

    openfire_url: "",
    openfire_port: null,

    client_filename: "",
    truststore_filename: "",

    client_password: "",
    truststore_password: "",

    client_file_location: "",
    truststore_file_location: "",

    client_file_type: "",
    truststore_file_type: "",

    client_file_size: null,
    truststore_file_size: null,
  };

  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [fedState] = useContext(FederationDataContext);

  const formik = useFormikContext();
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;
  const moduleItemData = formik.values;
  const handleFederationConnection = props.handleFederationConnection;

  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const amIConnected =
    fedState.federationConnection.federationServerId === formik.values.id;

  useEffect(() => {
    if (formik.values.features) {
      setSelectedFeatures(formik.values.features.split(","));
    }
  }, [formik.values.features]);

  useEffect(() => {
    if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
      const fedData = mwState.moduleItemData;
      setMwState((p) => ({
        ...p,
        moduleItemData: {
          ...newFed,
          ...fedData,
          connected: amIConnected,
        },
        moduleItemDataOriginal: {
          ...newFed,
          ...fedData,
          connected: amIConnected,
        },
      }));
    }
  }, [mwState.wrapperDisplayMode]);

  const fedFeatures = [
    { label: "POI", id: "pois" },
    { label: "Geofences", id: "geofences" },
    { label: "Asset Location", id: "assets" },
  ];

  const handleFeaturesChange = (event) => {
    const { value } = event.target;
    const features = formik.values.features
      ? formik.values.features.split(",")
      : [];
    let updatedFeatures;
    if (features.includes(value)) {
      updatedFeatures = features.filter((feature) => feature !== value);
    } else {
      updatedFeatures = [...features, value];
    }
    formik.setFieldValue("features", updatedFeatures.join(","));
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <p className="pullRight">
              {"*" + Language.translate("Required fields")}
            </p>
          </Grid>
          <Grid item xs={12}>
            {!_.isEmpty(mwState.moduleItemDataOriginal) && formik.values.id && (
              <CustomSwitch
                name="connected"
                label={
                  amIConnected
                    ? Language.translate("Connected")
                    : Language.translate("Disconnected")
                }
                value={fedState.federationConnection?.federationServerId}
                onChange={() => handleFederationConnection(formik.values)}
                checked={amIConnected}
                color="primary"
                labelPlacement="right"
                disabled={mwState.moduleFormHasChanges}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label={`${Language.translate("Name")}*`}
              value={moduleItemData.name}
              name="name"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label={`${Language.translate("Host URL")}*`}
              value={moduleItemData.host_url}
              name="host_url"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.host_url && Boolean(formik.errors.host_url)}
              helperText={formik.touched.host_url && formik.errors.host_url}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label={`${Language.translate("Username")}*`}
              value={moduleItemData.username}
              name="username"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label={`${Language.translate("Password")}*`}
              value={moduleItemData.password}
              name="password"
              type="password"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12} className={classes.checkbox}>
            {Language.translate("Features")}*&nbsp;
            {formik.touched.features && (
              <span className={classes.errorText}>
                {formik.errors.features}
              </span>
            )}
            <br />
            {fedFeatures.map((feature) => (
              <CustomCheckbox
                label={feature.label}
                key={feature.id}
                value={feature.id}
                name="features"
                onChange={handleFeaturesChange}
                onBlur={handleFormBlur}
                color="primary"
                checked={selectedFeatures.includes(feature.id)}
              />
            ))}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
