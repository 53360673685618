import { makeStyles } from "@material-ui/core";
import LeakAddIcon from "@material-ui/icons/LeakAdd";

// External
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    position: "relative",
    display: "inline-block",
  },
  headerIcon: {
    maxWidth: 28,
    maxHeight: 28,
  },
  listIcon: {
    maxWidth: 20,
    maxHeight: 20,
  },
  overlayIcon: {
    position: "absolute",
    bottom: "10%",
    right: "10%",
    fontSize: 12,
    color: theme.palette.colors.black.main,
  },
  inclusiveCircle: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.geofence.greenIcon.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    borderRadius: "50%",
  },
  exclusiveCircle: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.geofence.redIcon.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    borderRadius: "50%",
    transform: "rotate(-45deg)",
  },
  inclusivePath: {
    width: 20,
    height: 0,
    backgroundColor: theme.palette.geofence.greenIcon.dark,
    border: `1px solid ${theme.palette.geofence.greenIcon.dark}`,
    transform: "rotate(-45deg)",
  },
  exclusivePath: {
    width: 20,
    height: 0,
    backgroundColor: theme.palette.geofence.redIcon.dark,
    border: `1px solid ${theme.palette.geofence.redIcon.dark}`,
    transform: "rotate(-45deg)",
  },
  inclusiveRec: {
    width: 20,
    height: 10,
    backgroundColor: theme.palette.geofence.greenIcon.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
  },
  exclusiveRec: {
    width: 20,
    height: 10,
    backgroundColor: theme.palette.geofence.redIcon.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
  },
  inclusivePolygon: {
    position: "relative",
    height: 20,
    width: 11.55,
    backgroundColor: theme.palette.geofence.greenIcon.main,
    margin: "5.77px 0px",
    "&::before": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      right: "100%",
      borderRight: `5.77px solid ${theme.palette.geofence.greenIcon.main}`,
    },
    "&::after": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      left: "100%",
      borderLeft: `5.77px solid ${theme.palette.geofence.greenIcon.main}`,
    },
  },
  exclusivePolygon: {
    position: "relative",
    height: 20,
    width: 11.55,
    backgroundColor: theme.palette.geofence.redIcon.main,
    margin: "5.77px 0px",
    "&::before": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      right: "100%",
      borderRight: `5.77px solid ${theme.palette.geofence.redIcon.main}`,
    },
    "&::after": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      left: "100%",
      borderLeft: `5.77px solid ${theme.palette.geofence.redIcon.main}`,
    },
  },
}));

function GeofenceIcon(props) {
  const data = props.geofence;
  const isFederationData = props.isFederationData;
  const header = props.header || false;
  const classes = useStyles();

  return (
    <div className={classes.iconContainer}>
      {(() => {
        switch (data.shape) {
          case "polygon":
            return (
              <div
                className={clsx(
                  data.inclusive
                    ? classes.inclusivePolygon
                    : classes.exclusivePolygon,
                  header ? classes.headerIcon : classes.listIcon
                )}
              />
            );
          case "path":
            return (
              <div
                className={
                  data.inclusive ? classes.inclusivePath : classes.exclusivePath
                }
              />
            );
          case "rectangle":
            return (
              <div
                className={
                  data.inclusive ? classes.inclusiveRec : classes.exclusiveRec
                }
              />
            );
          case "circle":
          default:
            return (
              <div
                className={
                  data.inclusive
                    ? classes.inclusiveCircle
                    : classes.exclusiveCircle
                }
              />
            );
        }
      })()}
      {isFederationData && <LeakAddIcon className={classes.overlayIcon} />}
    </div>
  );
}

export default GeofenceIcon;
