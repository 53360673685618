import React, { useState } from "react";

//Internal
import Utils from "sccUtils";
import Poi from "sccPoi";
import PoiOverlay from "sccPoiOverlay";
import Geofence from "sccGeofence";
import GeofenceOverlay from "sccGeofenceOverlay";
import Device from "sccDevice";
import DeviceOverlay from "sccDeviceOverlay";
import Images from "sccImage";
import PoiCategory from "sccPoiCategory";
import UserSetting from "sccUserSetting";
import TimeUtils from "sccTimeUtils";

// External
import _ from "lodash";

const FederationDataContext = React.createContext([{}, () => {}]);

const FederationDataContextProvider = (props) => {
  const REACT_APP_FEDERATION_POI_IMAGE_DEFAULT =
    process.env.REACT_APP_FEDERATION_POI_IMAGE_DEFAULT;
  const REACT_APP_FEDERATION_ASSET_TYPE_DEFAULT =
    process.env.REACT_APP_FEDERATION_ASSET_TYPE_DEFAULT;
  const REACT_APP_FEDERATION_POI_CATEGORY_DEFAULT =
    process.env.REACT_APP_FEDERATION_POI_CATEGORY_DEFAULT;

  const checkFederationConnection = async () => {
    const fedConnection = await Utils.federationConnectionStatus();
    setFedState((p) => ({
      ...p,
      federationConnection: fedConnection,
    }));
  };
  const fetchFederationData = async () => {
    var options = {
      url: Utils.apiUrlPrefix + "/tak/getFederatedData",
      method: "GET",
    };
    try {
      const response = await Utils.httpRequestHandler(options);
      if (response?.data?.result) {
        const externalData = formatFedData(response.data.result);
        setFedState((p) => ({
          ...p,
          data: externalData,
          version: Date.now() / 1000,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeFederationData = () => {
    setFedState((p) => ({
      ...p,
      data: formatFedData([]),
      federationConnection: {},
      version: Date.now() / 1000,
    }));
  };

  const initialState = {
    fetchFederationData,
    removeFederationData,
    checkFederationConnection,
    data: [],
    federationConnection: {},
    version: Date.now() / 1000,
  };

  //initial state
  const [fedState, setFedState] = useState(initialState);

  const formatFedData = (data) => {
    //HARDCODED FOR NOW
    //Updating local objects with the external data
    if (data.length > 0) {
      data.map((server) => {
        const federationServerId = server.federationServerId;
        const federationServerName = server.federationServerName;
        if (server.pois && Poi.initialized) {
          const pois = Object.values(server.pois) || [];
          Poi.reset(true);
          PoiOverlay.reset(true);
          pois.map((poi) => {
            poi.id =
              federationServerName.replaceAll(" ", "_") +
              "_" +
              federationServerId +
              "_" +
              poi.id;
            const defaultIconForFederationPoi = Object.values(
              Images.get()
            ).filter(
              (item) => item.name === REACT_APP_FEDERATION_POI_IMAGE_DEFAULT
            );

            //since the non NATO poi images on the other system will have different id
            if (poi.image_id) {
              const parallelSystemIcons = Object.values(Images.get()).filter(
                (item) => item.name === poi.image_id //image_id contains image name
              );
              poi.image_id = parallelSystemIcons.length
                ? parallelSystemIcons[0].id
                : defaultIconForFederationPoi[0]?.id;
            }
            //since the custom poi categories on the other system will always be different
            //we check if it is not default and replace it with the FEDERATED POIs category
            if (poi.category_id && poi.category_id !== 1) {
              const federatedSystemCategory = Object.values(
                PoiCategory.get()
              ).filter(
                (item) =>
                  item.title === REACT_APP_FEDERATION_POI_CATEGORY_DEFAULT
              );
              poi.image_id =
                federatedSystemCategory[0]?.images[0] ||
                defaultIconForFederationPoi[0]?.id;
              poi.category_id = federatedSystemCategory[0].id;
              poi.category_name = undefined;
            }
            poi.federationServerId = federationServerId;
            poi.federationServerName = federationServerName;
            Poi.set(poi, poi.id, true);
            PoiOverlay.refresh(Poi.get(poi.id), null);
          });
        } else if (Poi.initialized) {
          Poi.reset();
          PoiOverlay.reset(true);
        }

        if (server.geofences && Geofence.initialized) {
          const geofences = Object.values(server.geofences) || [];
          Geofence.reset();
          GeofenceOverlay.reset(true);
          geofences.map((geofence) => {
            geofence.id =
              federationServerName.replaceAll(" ", "_") +
              "_" +
              federationServerId +
              "_" +
              geofence.id;
            geofence.federationServerId = federationServerId;
            geofence.federationServerName = federationServerName;
            if (geofence.custom_colour_on) {
              geofence.custom_colour_details = {
                hex: geofence.custom_colour_details?.custom_colour_hex,
                label: geofence.custom_colour_details?.custom_colour_label,
              };
            }
            Geofence.set(geofence, geofence.id, true);
            GeofenceOverlay.refresh(geofence);
          });
        } else if (Geofence.initialized) {
          Geofence.reset();
          GeofenceOverlay.reset(true);
        }

        if (server.devices && Device.initialized) {
          const localDeviceTypes = {};
          for (const key in Device._deviceType) {
            const value = Device._deviceType[key];
            localDeviceTypes[value.title] = {
              type_id: value.id,
              type: value.title,
            };
          }
          const devices = Object.values(server.devices) || [];
          Device.reset();
          DeviceOverlay.initialized && DeviceOverlay.reset(true);
          devices.map((device) => {
            device.id =
              federationServerName.replaceAll(" ", "_") +
              "_" +
              federationServerId +
              "_" +
              device.id;
            if (_.isObject(localDeviceTypes[device.type])) {
              device.type_id = localDeviceTypes[device.type].type_id;
            } else {
              device.typeOriginal = device.type;
              device.type = REACT_APP_FEDERATION_ASSET_TYPE_DEFAULT;
              device.type_id =
                localDeviceTypes[
                  REACT_APP_FEDERATION_ASSET_TYPE_DEFAULT
                ].type_id;
            }
            device.federationServerId = federationServerId;
            device.federationServerName = federationServerName;
            Device.set(device, device.id, true);
            if (
              device.longitude &&
              device.latitude &&
              DeviceOverlay.initialized
            )
              DeviceOverlay.refresh(Device.get(device.id), null);
          });
          DeviceOverlay.reset(true);
        } else if (Device.initialized) {
          Device.reset();
          DeviceOverlay.initialized && DeviceOverlay.reset(true);
        }
      });
    } else {
      if (Poi.initialized) {
        Poi.reset();
        PoiOverlay.reset(true);
      }
      if (Geofence.initialized) {
        Geofence.reset();
        GeofenceOverlay.reset(true);
      }
      if (Device.initialized) {
        Device.reset();
        DeviceOverlay.reset(true);
      }
    }
    return data;
  };

  return (
    <FederationDataContext.Provider value={[fedState, setFedState]}>
      {props.children}
    </FederationDataContext.Provider>
  );
};

export { FederationDataContext, FederationDataContextProvider };
