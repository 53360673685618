import React, { useState } from "react";

// Material-UI
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

// Styles
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  // ...moduleListingDetails(theme),
  detailsContainer: {
    margin: 0,
    backgroundColor: theme.palette.colors.white.main,
    color: theme.palette.colors.black.main,
    "& .MuiList-padding": {
      paddingTop: 0,
    },
    "& .MuiListItem-gutters": {
      // paddingLeft: 6,
      // paddingRight: 6,
    },
    "& .MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
      alignItems: "inherit !important",
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
      "& .MuiChip-root": {
        paddingLeft: 2,
        flex: "0 1 auto",
      },
    },
    "& .MuiSvgIcon-root": {
      marginRight: 2,
    },
    "& .itemTextModule": {
      paddingBottom: 0,
    },
    "& .itemTextTitle": {
      flexDirection: "row-reverse",
      width: "96%",
      paddingTop: 14,
      paddingLeft: 4,
      marginLeft: 16,
      // padding: '14px, 0px, 0px, 14px',
    },
    "& .itemTextFirst": {
      width: "40%",
      background: theme.palette.colors.gray.main,
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      padding: "4px 8px",
    },
    "& .itemTextSecond": {
      width: "60%",
      minHeight: "27px",
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      padding: "4px 8px",
      marginLeft: -1,
      "& .MuiChip-root": {
        marginRight: 5,
        color: "red",
      },
    },
  },
}));

export default function TakDetail(props) {
  const { data } = props;
  const [openOpenfire, setOpenOpenfire] = useState(false);
  const [openClientCert, setOpenClientCert] = useState(false);
  const [openTruststore, setOpenTruststore] = useState(false);
  const classes = useStyles();

  const handleToggleOpenfire = () => {
    console.log(
      JSON.stringify(
        classes.detailsContainer["makeStyles-detailsContainer-418"]
      )
    );
    setOpenOpenfire(!openOpenfire);
  };

  const handleToggleClientCert = () => {
    setOpenClientCert(!openClientCert);
  };

  const handleToggleTruststore = () => {
    setOpenTruststore(!openTruststore);
  };

  return (
    <Grid item xs={12} md={12} className={classes.detailsContainer}>
      <List>
        {/* General */}
        <div className="itemTextModule">
          <ListItem className="itemTextTitle">
            <ListItemText primary={Language.translate("General")} />
          </ListItem>
          <ListItem>
            <ListItemText
              className="itemTextFirst"
              primary={Language.translate("Name*")}
            />
            <ListItemText className="itemTextSecond" primary={data.name} />
          </ListItem>
          <ListItem>
            <ListItemText
              className="itemTextFirst"
              primary={Language.translate("Username")}
            />
            <ListItemText className="itemTextSecond" primary={data.username} />
          </ListItem>
          <ListItem>
            <ListItemText
              className="itemTextFirst"
              primary={Language.translate("Password")}
            />
            <ListItemText className="itemTextSecond" primary={data.password} />
          </ListItem>
          <ListItem>
            <ListItemText
              className="itemTextFirst"
              primary={Language.translate("Host URL")}
            />
            <ListItemText className="itemTextSecond" primary={data.host_url} />
          </ListItem>
          <ListItem>
            <ListItemText
              className="itemTextFirst"
              primary={Language.translate("Port - Input")}
            />
            <ListItemText
              className="itemTextSecond"
              primary={data.input_port}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              className="itemTextFirst"
              primary={Language.translate("Port - API")}
            />
            <ListItemText className="itemTextSecond" primary={data.api_port} />
          </ListItem>
        </div>

        {/* Openfire Section */}
        <div className="itemTextModule">
          <ListItem
            button
            className="itemTextTitle"
            onClick={handleToggleOpenfire}
          >
            <ListItemText primary={Language.translate("Openfire")} />
            {openOpenfire ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openOpenfire} timeout="auto" unmountOnExit>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("URL")}
              />
              <ListItemText
                className="itemTextSecond"
                primary={data.openfire_url}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Port")}
              />
              <ListItemText
                className="itemTextSecond"
                primary={data.openfire_port}
              />
            </ListItem>
          </Collapse>
        </div>

        {/* Client Certificate Section */}
        <div className="itemTextModule">
          <ListItem
            button
            className="itemTextTitle"
            onClick={handleToggleClientCert}
          >
            <ListItemText primary={Language.translate("Client Certificate")} />
            {openClientCert ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openClientCert} timeout="auto" unmountOnExit>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Username")}
              />
              <ListItemText
                className="itemTextSecond"
                primary={data.client_filename}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Password")}
              />
              <ListItemText
                className="itemTextSecond"
                primary={data.client_password}
              />
            </ListItem>

            {/* ***Location */}
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("File Location")}
              />
              <ListItemText
                className="itemTextSecond"
                primary={data.client_file_location}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("File Size")}
              />
              <ListItemText
                className="itemTextSecond"
                primary={data.client_file_size}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("File Type")}
              />
              <ListItemText
                className="itemTextSecond"
                primary={data.client_file_type}
              />
            </ListItem>
          </Collapse>
        </div>

        {/* Truststore Section */}
        <div className="itemTextModule">
          <ListItem
            button
            className="itemTextTitle"
            onClick={handleToggleTruststore}
          >
            <ListItemText
              primary={Language.translate("Truststore Certificate")}
            />
            {openTruststore ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openTruststore} timeout="auto" unmountOnExit>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Username")}
              />
              <ListItemText
                className="itemTextSecond"
                primary={data.truststore_filename}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("Password")}
              />
              <ListItemText
                className="itemTextSecond"
                primary={data.truststore_password}
              />
            </ListItem>

            {/* ***Location */}
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("File Location")}
              />
              <ListItemText
                className="itemTextSecond"
                primary={data.truststore_file_location}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("File Size")}
              />
              <ListItemText
                className="itemTextSecond"
                primary={data.truststore_file_size}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                className="itemTextFirst"
                primary={Language.translate("File Type")}
              />
              <ListItemText
                className="itemTextSecond"
                primary={data.truststore_file_type}
              />
            </ListItem>
          </Collapse>
        </div>
      </List>
    </Grid>
  );
}

// file location and last updated
// file size and type are hidden
// follow file structure to upload file location
// home/nortac/files/
